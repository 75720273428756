import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Api from "../../../services/ApiService";
import { mutationErrorHandler, queryKeys } from "../../../react-query";

export type VacancyAlarmRegisterType = {
  thingId: number;
  days: string[];
  startTime: string;
  endTime: string;
};

const registerVacancyAlarm = async (vacancyAlarm: VacancyAlarmRegisterType) => {
  const response = await Api.post("/vacancy-alarm", vacancyAlarm);
  return response;
};

export const useRegisterVacancyAlarm = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data: response,
    isError,
    isSuccess,
    error,
    mutate,
    mutateAsync,
  } = useMutation({
    mutationFn: (vacancyAlarm: VacancyAlarmRegisterType) =>
      registerVacancyAlarm(vacancyAlarm),
    onError: (err) => {
      mutationErrorHandler(err);
    },
    onSuccess: () => {
      navigate(-1);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.vacancyAlarm]);
    },
  });
  return { response, isError, isSuccess, error, mutate, mutateAsync };
};
