import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../react-query/queryKeys";
import Api from "../../../services/ApiService";
import { MenuType } from "../../../model/MenuType";
import { useIsAuthenticated } from "../../AuthRequiredApp/hooks/useIsAuthenticated";

async function getMenu(): Promise<MenuType[]> {
  const { data } = await Api.get<MenuType[]>("/thing");
  return data;
}

export function useMenu(): MenuType[] {
  const isAuthenticated = useIsAuthenticated();
  const fallback: MenuType[] = [];
  const { data = fallback } = useQuery<MenuType[]>(
    [queryKeys.menu],
    () => getMenu(),
    { enabled: isAuthenticated }
  );
  return data;
}
