import { FC } from "react";
import { Box, Theme, Typography, styled, useMediaQuery } from "@mui/material";
import { SlackLogin } from "../../molecules";
import { BUILD_CENTER, LAUNCH_CENTER } from "../../../react-query/constants";
import { SlackLoginPageTemplateProps } from "./SlackLoginPageTemplate.type";
import { Image } from "../../atoms";

const MobileSlackLoginPageContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

const DesktopSlackLoginPageContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  paddingTop: "120px",
  height: "650px",
}));

export const SlackLoginPageTemplate: FC<SlackLoginPageTemplateProps> = () => {
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("laptop")
  );
  if (isTablet) {
    return (
      <MobileSlackLoginPageContainer>
        <Box width="fit-content" textAlign="center">
          <Image src="./assets/img/img_calender.svg" />
          <Typography fontSize="32px" marginTop="30px">
            Build X Launch
          </Typography>
          <Typography fontSize="32px">Reserve</Typography>
          <Typography fontSize="14px">
            LG CNS 빌드/론치센터 통합예약시스템
          </Typography>
          <Typography fontSize="14px" marginTop="60px" marginBottom="5px">
            빌드센터 로그인
          </Typography>
          <SlackLogin center={BUILD_CENTER} />
          <Typography fontSize="14px" marginTop="24px" marginBottom="5px">
            론치센터 로그인
          </Typography>
          <SlackLogin center={LAUNCH_CENTER} />
          <Typography fontSize="12px" marginTop="12px">
            Slack을 통해 센터원 인증 후 로그인 해주세요.
          </Typography>
        </Box>
      </MobileSlackLoginPageContainer>
    );
  }

  return (
    <DesktopSlackLoginPageContainer>
      <Box
        width="50%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRight="1px solid #DDDDDD"
        maxWidth="500px"
      >
        <Image src="./assets/img/img_calender.svg" />
        <Typography fontSize="35px" marginTop="30px">
          Build X Launch
        </Typography>
        <Typography fontSize="35px">Reserve</Typography>
        <Typography fontSize="25px" marginTop="27px">
          LG CNS 빌드/론치센터 통합예약시스템
        </Typography>
        <Typography fontSize="20px" marginTop="35px">
          Slack을 통해 센터원 인증 후 로그인 해주세요.
        </Typography>
      </Box>
      <Box
        width="50%"
        display="column"
        justifyContent="center"
        maxWidth="500px"
      >
        <Box
          borderBottom="1px solid #DDDDDD"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="50%"
        >
          <Typography fontSize="25px" marginBottom="44px">
            빌드센터 로그인
          </Typography>
          <SlackLogin center={BUILD_CENTER} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="50%"
        >
          <Typography fontSize="25px" marginBottom="44px">
            론치센터 로그인
          </Typography>
          <SlackLogin center={LAUNCH_CENTER} />
        </Box>
      </Box>
    </DesktopSlackLoginPageContainer>
  );
};
