import { Box, styled } from "@mui/material";
import { AlarmCard } from "../../molecules";
import { useVacancyAlarmList } from "../../../pages/VacancyAlarmPage/hook/useVacancyAlarmList";
import { VacancyAlarmType } from "../../../model/VacancyAlarm.type";
import { Days } from "../../../common/commonContants";

const VacancyAlarmListWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  [theme.breakpoints.down("tablet")]: {
    gap: "4px",
    marginTop: "10px",
  },
  [theme.breakpoints.up("tablet")]: {
    gap: "12px",
  },
}));

export const EmptyListInfoText = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  [theme.breakpoints.down("tablet")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("tablet")]: { fontSize: "20px" },
}));

export type VacancyAlarmListProps = {
  imageUrl?: string;
  isDelete?: boolean;
};

export const VacancyAlarmList = ({
  imageUrl,
  isDelete = false,
}: VacancyAlarmListProps) => {
  const { response: vacancyAlarmList } = useVacancyAlarmList();

  return (
    <Box height="100%">
      {vacancyAlarmList && (
        <VacancyAlarmListWrapper>
          {vacancyAlarmList.length === 0 ? (
            <EmptyListInfoText>
              아직 등록된 빈자리 알림이 없어요 🥲
            </EmptyListInfoText>
          ) : (
            <>
              {vacancyAlarmList.map((alarm: VacancyAlarmType) => (
                <AlarmCard
                  key={alarm.vacancyAlarmGroupId}
                  imageUrl={imageUrl}
                  time={`${alarm.startTime.slice(0, 5)} - ${alarm.endTime.slice(
                    0,
                    5
                  )}`}
                  days={alarm.days.map((day: keyof typeof Days) => Days[day])}
                  vacancyAlarmGroupId={alarm.vacancyAlarmGroupId}
                  isDelete={isDelete}
                />
              ))}
            </>
          )}
        </VacancyAlarmListWrapper>
      )}
    </Box>
  );
};
