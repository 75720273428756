import { ButtonBase, Typography, styled } from "@mui/material";
import { Image } from "../../atoms";
import { REDIRECT_SLACK_PATH } from "../../../react-query/constants";
import { SlackLoginProps } from "./SlackLogin.type";

const SlackLoginWrapper = styled(ButtonBase)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("laptop")]: {
    width: "296px",
    height: "56px",
    fontSize: "18px",
  },
  [theme.breakpoints.up("laptop")]: {
    width: "350px",
    height: "70px",
    fontSize: "24px",
  },
  border: "1px solid #DDDDDD",
  borderRadius: "56px",
}));

const StyledImage = styled(Image)(({ theme }) => ({
  [theme.breakpoints.down("laptop")]: {
    width: "24px",
    height: "24px",
  },
  [theme.breakpoints.up("laptop")]: {
    width: "32px",
    height: "32px",
  },
  marginRight: "12px",
}));
export const SlackLogin = ({ center }: SlackLoginProps) => (
  <a
    href={`${
      (process.env.REACT_APP_API_HOST as string) + REDIRECT_SLACK_PATH
    }?center=${center as string}`}
    style={{ textDecoration: "none", color: "black" }}
  >
    <SlackLoginWrapper>
      <StyledImage src="/assets/img/img_slack.svg" />
      <Typography fontFamily="Lato" fontWeight={700}>
        Sign in with Slack
      </Typography>
    </SlackLoginWrapper>
  </a>
);
