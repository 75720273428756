import { QueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useModalStore } from "../zustand";
import { CommonResponse } from "../services";

export function showErrorModal(errorMessage: string): void {
  useModalStore.setState({
    headerTitle: "에러가 발생했습니다.",
    mainContent: [errorMessage],
  });
  if (window.location.hash === "#confirm") {
    window.location.replace("#error");
  }
  window.location.assign("#error");
}

export function mutationErrorHandler(error: unknown) {
  if (
    (error as AxiosError).config?.url?.includes("reissue") ||
    (error as AxiosError).config?.url?.includes("logout")
  ) {
    return;
  }

  if (error instanceof Error) {
    if (axios.isAxiosError(error)) {
      showErrorModal(
        ((error as AxiosError).response as AxiosResponse<CommonResponse<any>>)
          ?.data.message ?? "알 수 없는 오류가 발생했습니다."
      );
    } else {
      showErrorModal(error.message || "알 수 없는 오류가 발생했습니다.");
    }
  } else {
    showErrorModal("예상치 못한 오류가 발생했습니다.");
  }
}

export function generateQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
        cacheTime: 0,
        retry: 0,
        useErrorBoundary: true, // query의 경우 에러 처리를 error boundary로 위임
      },
      mutations: {
        onError: mutationErrorHandler, // CUD시 에러처리 (useMutation)
      },
    },
  });
}

export const queryClient = generateQueryClient();
