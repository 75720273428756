import { useQuery } from "@tanstack/react-query";
import { Thing } from "../../../model/ReservationType";
import Api from "../../../services/ApiService";
import { queryKeys } from "../../../react-query";
import { useThingStore } from "../../../zustand";
import { useIsAuthenticated } from "../../AuthRequiredApp/hooks/useIsAuthenticated";

async function getReservationRule(thingId: number): Promise<Thing> {
  const { data } = await Api.get<Thing>(`/thing/policy/${thingId}`);
  return data;
}

export function useReservationRule(thingId: number): Thing | undefined {
  const isAuthenticated = useIsAuthenticated();
  const { selectedThing } = useThingStore();
  const { data } = useQuery<Thing>(
    [queryKeys.reservation],
    () =>
      getReservationRule(
        selectedThing?.thingId ? selectedThing.thingId : thingId
      ),
    {
      enabled: isAuthenticated,
    }
  );
  return data;
}
