import { AxiosError } from "axios";
import { useAuthStore } from "../../../zustand";
import { NotLoggedInApp } from "../LoginPage";
import { CommonResponse } from "../../../services";
import { ReserverStatusCode } from "../ReserveStatusCode.type";
import { ErrorHandlingComponent } from "./ErrorHandlingComponent";

export interface SilentLoginErrorHandlingComponentProps {
  error: AxiosError;
}

export const SilentLoginErrorHandlingComponent = ({
  error,
}: SilentLoginErrorHandlingComponentProps) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const onClick = () => {
    window.location.href = "/";
  };

  if (!isLoggedIn) {
    return <NotLoggedInApp />;
  }

  let message;
  switch ((error.response?.data as CommonResponse<null>)?.statusCode) {
    case ReserverStatusCode.JWT_TOKEN_EXPIRED:
      message = ["세션이 만료되었습니다.", "로그인 화면으로 돌아갑니다."];
      break;
    case ReserverStatusCode.ATK_NOT_EXIST:
    case ReserverStatusCode.INVALID_TOKEN_ERROR:
    case ReserverStatusCode.COOKIE_REQUIRED:
      message = ["세션 오류가 발생했습니다.", "로그인 화면으로 돌아갑니다."];
      break;
    default:
      message = ["서버와 통신 중 에러가 발생했습니다.", "메인으로 돌아갑니다."];
  }
  return (
    <ErrorHandlingComponent
      message={message}
      chipButton={{ label: "메인으로", onClick }}
    />
  );
};
