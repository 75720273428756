/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { styled, Table, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { ReservationCompleteCardType } from "./ReservationCompleteCard.type";

const StyledTable = styled(Table)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    width: "500px",
    height: "160px",
  },
  width: "300px",
  borderRadius: "8px",
  backgroundColor: theme.palette.grey[100],
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    fontSize: "20px",
  },
  fontSize: "14px",
  border: 0,
  borderRadius: "8px",
}));

export const ReservationCompleteCard: FC<ReservationCompleteCardType> = ({
  reserveDate,
  startTime,
  endTime,
}) => (
  <StyledTable>
    <tbody>
      <TableRow>
        <StyledTableCell align="center" variant="head">
          예약 일자
        </StyledTableCell>
        <StyledTableCell align="center">{reserveDate}</StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell align="center" variant="head">
          시간
        </StyledTableCell>
        <StyledTableCell align="center">
          {startTime.slice(0, 5)} - {endTime.slice(0, 5)}
        </StyledTableCell>
      </TableRow>
    </tbody>
  </StyledTable>
);
