/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError,
} from "axios";
import { CommonResponse } from "./services.types";
import { useAuthStore } from "../zustand";
import { refreshToken } from "./RefreshPromise";
import { ResponseStatus } from "./ResponseStatus.type";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
});

class Api {
  public static get = async <TResponse>(
    url: string,
    params?: object,
    headers?: object
  ): Promise<CommonResponse<TResponse>> => {
    const response = await axiosInstance.get<CommonResponse<TResponse>>(url, {
      params,
      headers,
    });

    return response.data;
  };

  public static post = async <T, TReqBody>(
    url: string,
    data: TReqBody
  ): Promise<CommonResponse<T>> => {
    const response = await axiosInstance.post<CommonResponse<T>>(url, data, {});

    return response.data;
  };

  public static put = async <T>(
    url: string,
    data: any
  ): Promise<CommonResponse<T>> => {
    const response = await axiosInstance.put<CommonResponse<T>>(url, data, {});
    return response.data;
  };

  public static delete = async <T>(
    url: string,
    data?: any
  ): Promise<CommonResponse<T>> => {
    const response = await axiosInstance.delete<CommonResponse<T>>(url, {
      data,
    });
    // return handleResponse(response);
    return response.data;
  };
}

export default Api;

// axios request interceptor
axiosInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig) => {
    // 모든 요청에 atk를 담아서 보내는 로직
    const { atk } = useAuthStore.getState();

    if (atk) {
      request.headers.Authorization = `Bearer ${atk}`;
    }

    return request;
  }
);

// axios response interceptor
axiosInstance.interceptors.response.use(
  // response가 정상적인 경우에 대한 interceptor
  (response: AxiosResponse) => {
    if (
      response.status === 200 &&
      (response as AxiosResponse<CommonResponse<any>>).data.successOrNot ===
        false
    ) {
      throw new Error(
        (response as AxiosResponse<CommonResponse<any>>).data.message ||
          "일시적인 오류가 발생했습니다."
      );
    }
    return response;
  },
  async (error: any) => {
    if (!isAxiosError(error)) {
      throw error;
    }

    if (
      error.code === AxiosError.ECONNABORTED ||
      error.code === AxiosError.ERR_NETWORK ||
      error.code === AxiosError.ETIMEDOUT
    ) {
      throw error;
    }

    // reissue는 재시도 하지 않음
    if (!error.config?.url?.includes("reissue")) {
      const serverStatus = (error.response?.data as CommonResponse<null>)
        .statusCode;
      if (
        serverStatus === ResponseStatus.ATK_NOT_EXIST ||
        serverStatus === ResponseStatus.INVALID_TOKEN ||
        serverStatus === ResponseStatus.JWT_TOKEN_EXPIRED ||
        serverStatus === ResponseStatus.RTK_NOT_EXIST ||
        serverStatus === ResponseStatus.COOKIRE_REQUIRED
      ) {
        const refreshPromise = await refreshToken();
        useAuthStore.setState({ isLoggedIn: true });
        useAuthStore.setState({ atk: refreshPromise.data.accessToken });

        const resendConfig = { ...error.config } as AxiosRequestConfig;
        resendConfig.headers = {
          ...resendConfig.headers,
          Authorization: `Bearer ${refreshPromise.data.accessToken}`,
        };

        const resendRequest = await axios(resendConfig);
        if (
          resendRequest.status === 200 &&
          (resendRequest as AxiosResponse<CommonResponse<any>>).data
            .successOrNot === false
        ) {
          throw new Error(
            (resendRequest as AxiosResponse<CommonResponse<any>>).data
              .message || "일시적인 오류가 발생했습니다."
          );
        }

        return resendRequest;
      }
    }

    throw error; // async 함수라서 rejected Promise를 만들어주기 위함
  }
);
