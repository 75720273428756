import { FC, useState } from "react";
import {
  styled,
  Box,
  AppBar,
  Toolbar,
  SwipeableDrawer,
  ListItem,
  List,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { MobileHeaderGNBType } from "./MobileHeaderGNB.type";
import { IconButton, ProfileCard } from "../../../components";

import menuIcon from "../../../assets/img/ico_menu.svg";
import homeIcon from "../../../assets/img/ico_home.svg";
import userIcon from "../../../assets/img/ico_user.svg";
import exitIcon from "../../../assets/img/ico_exit.svg";
import alarmIcon from "../../../assets/img/ico_alarm.svg";

const HeaderWrapper = styled(Box)(() => ({}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "#ffffff",
  border: `1px solid ${theme.palette.grey[100]}`,
}));

const PageTitle = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  color: "black",
  size: "20px",
}));

const MenuTitle = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: "black",
  "&.MuiListItemText-root .MuiListItemText-primary": {
    fontSize: "16px",
  },
}));

const SubMenuTitle = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  color: "black",
  "&.MuiListItemText-root .MuiListItemText-primary": {
    fontSize: "14px",
  },
}));

const CustomListItem = styled(ListItem)(() => ({
  width: "273px",
  padding: "4px",
}));

// const ProfileCard = styled(Box)(() => ({
//   height: "70px",
//   textAlign: "center",
// }));
export const MobileHeaderGNB: FC<MobileHeaderGNBType> = ({
  title,
  logoutHandler,
  isLoggedIn,
  myPageOnClickHandler,
  menuList,
  menuItemClickHandler,
  reserveButtonClickHandler,
  vacancyAlarmOnClickHandler,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <HeaderWrapper>
      <AppBar position="fixed" elevation={0}>
        <CustomToolbar>
          <IconButton
            icoUrl={menuIcon}
            icoAlt="menu"
            onClick={() => setOpen(true)}
          />
          <PageTitle>{title}</PageTitle>
        </CustomToolbar>
      </AppBar>
      <SwipeableDrawer
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <ProfileCard inSidebar />
        <List>
          <CustomListItem>
            <ListItemButton
              onClick={() => {
                reserveButtonClickHandler();
                setOpen(false);
              }}
            >
              <IconButton icoUrl={homeIcon} icoAlt="home" />
              <MenuTitle>예약서비스</MenuTitle>
            </ListItemButton>
          </CustomListItem>
          {menuList &&
            menuList.map((menuItem) => (
              <ListItemButton
                onClick={() => {
                  setOpen(false);
                  menuItemClickHandler(menuItem);
                }}
                key={menuItem.thingId}
              >
                <SubMenuTitle>{menuItem.name}</SubMenuTitle>
              </ListItemButton>
            ))}
          <CustomListItem
            onClick={() => {
              vacancyAlarmOnClickHandler();
              setOpen(false);
            }}
          >
            <ListItemButton onClick={() => ({})}>
              <IconButton
                icoUrl={alarmIcon}
                icoAlt="user"
                width="24px"
                height="24px"
              />
              <MenuTitle>빈자리 알림 등록</MenuTitle>
            </ListItemButton>
          </CustomListItem>
          <CustomListItem
            onClick={() => {
              myPageOnClickHandler();
              setOpen(false);
            }}
          >
            <ListItemButton onClick={() => ({})}>
              <IconButton icoUrl={userIcon} icoAlt="user" />
              <MenuTitle>마이페이지</MenuTitle>
            </ListItemButton>
          </CustomListItem>
          {isLoggedIn && (
            <CustomListItem>
              <ListItemButton onClick={logoutHandler}>
                <IconButton icoUrl={exitIcon} icoAlt="exit" />
                <MenuTitle>로그아웃</MenuTitle>
              </ListItemButton>
            </CustomListItem>
          )}
        </List>
      </SwipeableDrawer>
    </HeaderWrapper>
  );
};
