import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdditionalInfoMain } from "../../components";
import { useUserInfo, useUpdateMemberTeamAtSignIn } from "./hooks";
import { useModalStore } from "../../zustand";

export const AdditionalInfoMainPage = () => {
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const { response: userInfoResponse, isSuccess: userInfoIsSuccess } =
    useUserInfo();

  const { mutate: updateTeam } = useUpdateMemberTeamAtSignIn();
  const { setHeaderTitle, setModalType } = useModalStore();
  const navigate = useNavigate();

  const completeRegister = () => {
    if (selectedTeam === "") {
      setHeaderTitle("팀명을 선택해주세요.");
      setModalType("cancel");

      window.location.assign("#error");
    } else {
      updateTeam(selectedTeam);
    }
  };

  useEffect(() => {
    if (!userInfoResponse?.first) {
      navigate("/");
    }
  }, [userInfoResponse?.first]);

  if (userInfoIsSuccess && userInfoResponse?.first) {
    return (
      <AdditionalInfoMain
        userInfo={userInfoResponse}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        completeRegister={completeRegister}
      />
    );
  }

  return <div />;
};
