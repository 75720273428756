import { Box, Grid, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChipButton } from "../../../components";

const ErrorTemplateWrapper = styled(Grid)({
  justifyContent: "center",
  height: "100vh",
  width: "100%",
  alignItems: "center",
});

const ErrorTemplateMessage = styled(Typography)({
  textAlign: "center",
  fontSize: "20px",
});

export interface ErrorHandlingComponentProps {
  message: string[] | undefined;
  chipButton?: { label: string; onClick?: () => void };
}

export const ErrorHandlingComponent = ({
  message,
  chipButton,
}: ErrorHandlingComponentProps) => {
  const navigate = useNavigate();
  return (
    <ErrorTemplateWrapper container direction="column">
      <Box>
        {message &&
          message.map((text) => (
            <ErrorTemplateMessage key={text}>{text}</ErrorTemplateMessage>
          ))}
        {chipButton && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <ChipButton
              label={chipButton.label}
              onClick={chipButton.onClick || (() => navigate("/"))}
            />
          </div>
        )}
      </Box>
    </ErrorTemplateWrapper>
  );
};
