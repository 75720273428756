import { Box, Tabs as MuiTabs, Tab as MuiTab, styled } from "@mui/material";
import { FC } from "react";
import { TabPanelProps } from "./Tab.type";

export const TabWrapper = styled(Box)({
  display: "flex",
  flexFlow: "column",
  width: "100%",
});
export const Tabs = styled(MuiTabs)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  minHeight: 0,
  ".MuiTabs-flexContainer": {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  ".MuiTabs-indicator": {
    height: "3px",
    marginBottom: "-1px",
    backgroundColor: "black",
  },
}));

export const Tab = styled(MuiTab)({
  minWidth: 0,
  minHeight: "30px",
  width: "80px",
  padding: "0 10px 18px",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20px",
  textAlign: "center",
  "& + .MuiTab-root": {
    marginLeft: "10px",
  },
  "&.Mui-selected": {
    fontWeight: 700,
    color: "black",
  },
});

const TabPanelComponent: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`content-tabpanel-${index}`}
      aria-labelledby={`content-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const TabPanel = styled(TabPanelComponent)({
  width: "100%",
  flex: "1 1 auto",
  paddingTop: "20px",
});
