import { FC, useState } from "react";
import {
  Grid,
  Theme,
  Box,
  styled,
  useMediaQuery,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import { Avatar, Button, IconButton, Select } from "../../../components";
import {
  ProfilCardProps,
  ProfilCardTextBoxProps,
  ProfileInfoWrapperProps,
  StyledAvatarProps,
} from "./ProfileCard.type";
import editIcon from "../../../assets/img/ico_edit.svg";
import { useTeams, useUserInfo } from "../../../pages";
import { BUILD_CENTER, LAUNCH_CENTER } from "../../../react-query";
import { useUpdateProfile } from "../../../pages/MyPage/hooks/useUpdateProfile";
import { CenterId } from "../../../common/commonContants";
import { useModalStore } from "../../../zustand";

const FONT_S = "14px";
const FONT_M = "16px";
const FONT_L = "20px";

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== "inSidebar",
})<StyledAvatarProps>(({ theme, inSidebar }) => ({
  [theme.breakpoints.down("tablet")]: {
    margin: theme.spacing(1, 1, 1, 1),
  },
  [theme.breakpoints.up("tablet")]: {
    margin: inSidebar ? theme.spacing(1, 1, 1, 1) : theme.spacing(2, 2, 2, 2),
  },
}));

const ProfileInfoWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "inSidebar",
})<ProfileInfoWrapperProps>(({ theme, inSidebar }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("tablet")]: {
    padding: inSidebar ? theme.spacing(1, 1, 1, 1) : theme.spacing(2, 1, 1, 1),
  },
  [theme.breakpoints.up("tablet")]: {
    padding: inSidebar ? theme.spacing(1, 2, 1, 1) : theme.spacing(2, 3, 2, 2),
  },
  width: "100%",
  position: "relative",
}));

const ProfilCardTextBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "inSidebar",
})<ProfilCardTextBoxProps>(({ theme, type, inSidebar }) => ({
  [theme.breakpoints.down("tablet")]: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: inSidebar
      ? type === "nickname"
        ? FONT_M
        : FONT_S
      : type === "nickname"
      ? FONT_M
      : FONT_S,
  },
  [theme.breakpoints.up("tablet")]: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: inSidebar
      ? type === "nickname"
        ? FONT_M
        : FONT_S
      : type === "nickname"
      ? FONT_L
      : FONT_M,
  },
  wordBreak: "keep-all",
}));

const IconButtonWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "inSidebar",
})<StyledAvatarProps>(() => ({
  top: 0,
  right: 0,
  position: "absolute",
}));

export const ProfileCard: FC<ProfilCardProps> = ({ inSidebar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("tablet")
  );

  const { response: userInfo } = useUserInfo();
  const { response: teamsResponse } = useTeams(
    userInfo?.center?.centerId === CenterId.buildCenterId
      ? BUILD_CENTER
      : LAUNCH_CENTER
  );
  const { mutateAsync: updateProfile } = useUpdateProfile();

  const [edit, setEdit] = useState<boolean>();
  const [selectedTeam, setSelectedTeam] = useState<number | undefined>(
    userInfo?.team?.teamId
  );
  const [nickname, setNickname] = useState<string>(userInfo?.nickName ?? "");
  const { setModalType, setHeaderTitle, setMainContent } = useModalStore();

  const handleSaveProfile = async () => {
    if (!selectedTeam) return;
    if (nickname.length === 0) {
      setHeaderTitle("닉네임을 입력해주세요.");
      setModalType("error");
      setMainContent([""]);
      window.location.assign("#error");
      return;
    }
    try {
      await updateProfile({
        nickname: nickname,
        team: {
          teamId: selectedTeam,
          teamName:
            teamsResponse?.data.filter((t) => t.id === selectedTeam)[0].name ??
            "",
        },
      });
    } finally {
      setEdit(false);
    }
  };

  if (userInfo) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: `${inSidebar ? "273px" : ""}`,
          marginTop: `${inSidebar ? "10px" : ""}`,
          padding: `${inSidebar ? "8px 16px" : ""}`,
        }}
      >
        <StyledAvatar
          src={userInfo.profileImgUrl || "/assets/img/img_profile_01.svg"}
          size={inSidebar ? "m" : isMobile ? "m" : "l"}
          inSidebar={inSidebar}
        />
        {!edit ? (
          <ProfileInfoWrapper inSidebar={inSidebar} rowGap={0.5}>
            <ProfilCardTextBox
              type="nickname"
              inSidebar={inSidebar}
              sx={{ marginBottom: "10px" }}
            >
              {userInfo.nickName}
            </ProfilCardTextBox>
            <ProfilCardTextBox type="teamname" inSidebar={inSidebar}>
              {userInfo.team?.teamName}
            </ProfilCardTextBox>
            {!inSidebar && (
              <IconButtonWrapper>
                <IconButton
                  icoUrl={editIcon}
                  icoAlt="menu"
                  onClick={() => setEdit(true)}
                  width={isMobile ? "16px" : "20px"}
                  height={isMobile ? "16px" : "20px"}
                />
              </IconButtonWrapper>
            )}
          </ProfileInfoWrapper>
        ) : (
          <ProfileInfoWrapper inSidebar={inSidebar} rowGap={0.5}>
            <TextField
              fullWidth
              sx={{
                ".MuiInputBase-root .MuiInputBase-input": {
                  padding: 0,
                },
                "& fieldset": {
                  border: "none",
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: 0,
                },
              }}
              inputProps={{
                style: {
                  fontSize: isMobile ? FONT_M : FONT_L,
                },
              }}
              onChange={(e) => setNickname(e.target.value)}
              value={nickname}
            />

            {teamsResponse?.data && (
              <Select
                sx={{
                  height: isMobile ? "22px" : "30px",
                  width: "auto",
                  fontSize: isMobile ? FONT_S : FONT_M,
                }}
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value as number)}
              >
                {teamsResponse.data.map((team) => (
                  <MenuItem
                    key={team.name}
                    value={team.id}
                    sx={{
                      minHeight: "30px",
                      width: "auto",
                      fontSize: isMobile ? FONT_S : FONT_M,
                      paddingLeft: "10px",
                    }}
                  >
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            {!inSidebar && (
              <IconButtonWrapper>
                <Button
                  variant="outlined"
                  onClick={handleSaveProfile}
                  sx={{
                    minWidth: "",
                    width: "36px",
                    height: "16px",
                    fontSize: "10px",
                    borderRadius: "4px",
                    color: "black",
                    border: "0.5px solid grey",
                  }}
                >
                  저장
                </Button>
              </IconButtonWrapper>
            )}
          </ProfileInfoWrapper>
        )}
      </div>
    );
  }

  return <div />;
};
