import { FC, useEffect } from "react";
import { styled, Grid } from "@mui/material";

import { TimeTableType } from "./TimeTable.type";
import { ChipButton } from "../../atoms";
import { useReservationStore } from "../../../zustand";
import { TimeTableDetail } from "../../../model/ReservationType";

const TimeTableWrapper = styled(Grid)(() => ({
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
}));

const TimeTable = styled(Grid)(() => ({
  width: "90%",
  flexDirection: "row",
  display: "flex",
  overflowX: "scroll",
  marginLeft: "10px",
  marginRight: "10px",
}));

const scrollTimetableToGivenTime = (
  allAvailableTimes: TimeTableDetail[],
  time: string
) => {
  const idx = allAvailableTimes.findIndex((timeSlot, idx) => {
    if (idx === allAvailableTimes.length - 1 && timeSlot.endTime === time) {
      return true;
    }
    return timeSlot.startTime <= time && time < timeSlot.endTime;
  });

  if (idx >= 0) {
    const timetable = document.getElementById("timetable");
    if (!timetable) {
      return;
    }
    const item = timetable?.childNodes.item(idx) as HTMLDivElement;
    item.scrollIntoView({
      behavior: "smooth",
      inline: "center",
    });
  }
};

export const MobileTimeTable: FC<TimeTableType> = ({
  timeTable,
  checkReserveState,
  isBtnDisabled,
}) => {
  const { selectedTime, setSelectedTime } = useReservationStore();

  const handleChipBtnClick = (time: TimeTableDetail) => {
    setSelectedTime(time);
    checkReserveState(time);
  };

  useEffect(() => {
    scrollTimetableToGivenTime(
      timeTable.morning.concat(timeTable.afternoon),
      new Date().toLocaleTimeString("en-US", { hour12: false }).slice(0, 5)
    );
  }, []);

  return (
    <TimeTableWrapper container direction="row">
      <TimeTable id="timetable" columnGap={1}>
        {timeTable.morning.map((time: TimeTableDetail) => (
          <ChipButton
            className={selectedTime.startTime === time.startTime ? "check" : ""}
            onClick={() => handleChipBtnClick(time)}
            label={`${time.startTime} - ${time.endTime}`}
            key={time.startTime}
            disabled={isBtnDisabled(time)}
          />
        ))}
        {timeTable.afternoon.map((time: TimeTableDetail) => (
          <ChipButton
            className={selectedTime.startTime === time.startTime ? "check" : ""}
            onClick={() => handleChipBtnClick(time)}
            label={`${time.startTime} - ${time.endTime}`}
            key={time.startTime}
            disabled={isBtnDisabled(time)}
          />
        ))}
      </TimeTable>
    </TimeTableWrapper>
  );
};
