import { Navigate, Route, Routes } from "react-router-dom";
import { ConditionalHeaderRequiredTemplate, HashModal } from "../../components";
import { SlackLoginPage } from "../SlackLoginPage";

const LoginPage = () => (
  <>
    <HashModal />
    <ConditionalHeaderRequiredTemplate isLoginPage />
    <SlackLoginPage />
  </>
);

export const NotLoggedInApp = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/*" element={<Navigate to="login" />} />
  </Routes>
);
