class Format {
  public static dateISOFormat = function (this: any, date: Date): string {
    const newDate = new Date(date);
    const isoDateTime = new Date(
      newDate.getTime() - newDate.getTimezoneOffset() * 60000
    ).toISOString();

    return isoDateTime.split("T")[0];
  };

  public static dateToKoreanDate = function (this: any, date: Date): string {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();
    const weekDay = [
      "일요일",
      "월요일",
      "화요일",
      "수요일",
      "목요일",
      "금요일",
      "토요일",
    ];

    return `${year}년 ${month}월 ${day}일 ${weekDay[newDate.getDay()]}`;
  };
}
export default Format;
