import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { ReserveFormType } from "../../../model/ReservationType";
import Api from "../../../services/ApiService";
import { ReserveMutationProps } from "./useReserveThing";
import { queryKeys } from "../../../react-query";

async function cancelReservation(
  thingId: number | undefined,
  reserveForm: ReserveFormType
): Promise<ReserveFormType | null> {
  if (thingId) {
    const { data } = await Api.delete<ReserveFormType>(
      `/reserve/${thingId}/cancel`,
      reserveForm
    );
    return data;
  }
  return null;
}

export function useCancelReservation(): UseMutateFunction<
  ReserveFormType | null,
  unknown,
  ReserveMutationProps,
  unknown
> {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    ({ thingId, reserveForm }: ReserveMutationProps) =>
      cancelReservation(thingId, reserveForm),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.myPage]);
      },
    }
  );

  return mutate;
}
