import { Box, Theme, Typography, styled, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Image, RoundChip, IconButton } from "../../atoms";
import { AlarmCardType } from "./AlarmCard.type";
import deleteIcon from "../../../assets/img/ico_delete.svg";
import { useCancelVacancyAlarm } from "../../../pages/VacancyAlarmPage/hook/useCancelVacancyAlarm";
import { useModalStore } from "../../../zustand";

const AlarmCardWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    fontSize: "14px",
    padding: "5px 12px",
    height: "60px",
    gap: "5px",
  },
  [theme.breakpoints.up("tablet")]: {
    fontSize: "20px",
    padding: "10px 20px",
    height: "65px",
    gap: "20px",
    paddingLeft: "50px",
  },
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  background: "#FFFFFF",
  borderRadius: "8px",
  textAlign: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
}));

const DayChipWrapper = styled(Box)(({ theme }) => ({
  flex: "1",
  display: "flex",
  [theme.breakpoints.down("tablet")]: {
    gap: "4px",
    width: "160px",
  },
  [theme.breakpoints.up("tablet")]: {
    gap: "15px",
    paddingLeft: "40px",
    width: "300px",
  },
}));

const DayContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("tablet")]: {
    paddingRight: "3px",
  },
  [theme.breakpoints.up("tablet")]: {
    paddingRight: "40px",
  },
}));

export const AlarmCard = ({
  vacancyAlarmGroupId,
  imageUrl = "",
  days = [],
  time = "",
  isDelete,
}: AlarmCardType) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("tablet")
  );
  const navigate = useNavigate();
  const { setModal } = useModalStore();
  const { mutateAsync: cancelMutation } = useCancelVacancyAlarm();

  const onClickDelete = () => {
    setModal({
      modalType: "confirm",
      headerTitle: "빈자리 알림을 삭제하시겠습니까?",
      mainContent: [`요일 : ${days.join(", ")}`, `시간 : ${time}`],
      modalAction: () => {
        cancelMutation(vacancyAlarmGroupId);
        navigate(-1);
      },
    });

    window.location.assign("#confirm");
  };

  return (
    <AlarmCardWrapper>
      <Image
        src={imageUrl}
        title="test"
        width={isMobile ? "32px" : "48px"}
        height={isMobile ? "32px" : "48px"}
      />
      <DayChipWrapper>
        {days.map((day: string) => (
          <RoundChip size={isMobile ? "s" : "l"} label={day} />
        ))}
      </DayChipWrapper>
      <DayContainer>
        <Typography fontSize={isMobile ? "12px" : "18px"}>{time}</Typography>
      </DayContainer>
      {isDelete && (
        <IconButton
          icoUrl={deleteIcon}
          width={isMobile ? "16px" : "25px"}
          icoAlt="user"
          onClick={onClickDelete}
        />
      )}
    </AlarmCardWrapper>
  );
};
