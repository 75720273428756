import { useEffect, useMemo, useState } from "react";
import {
  Box,
  MenuItem,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { Button, RoundChip, Select } from "../../atoms";
import {
  Days,
  EAvailDay,
  Weekdays,
  Weekends,
} from "../../../common/commonContants";
import { useReservationRule } from "../../../pages";
import { createTimeTableWithStartAndEnd } from "../../../common/utils/timeTableUtils";
import { useRegisterVacancyAlarm } from "../../../pages/VacancyAlarmPage/hook/useRegisterVacancyAlarm";
import { useModalStore } from "../../../zustand";

const VacancyAlarmFormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
    alignItems: "center",
  },
  flexDirection: "column",
}));

const DayChipWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    gap: "10px",
  },
  [theme.breakpoints.up("tablet")]: {
    gap: "12px",
  },
  display: "flex",
  padding: "4px 8px",
}));

const SelectWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("tablet")]: {
    gap: "10px",
    padding: "8px 16px",
  },
  [theme.breakpoints.up("tablet")]: {
    gap: "12px",
  },
}));

const InfoMessage = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    gap: "5px",
  },
  [theme.breakpoints.up("tablet")]: {
    gap: "10px",
    marginBottom: "20px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
  },
  [theme.breakpoints.up("tablet")]: {
    gap: "30px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
}));

export type VacancyAlarmFormProps = {
  thingId?: number;
};

export const VacancyAlarmForm = ({ thingId }: VacancyAlarmFormProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("tablet")
  );

  const [selectedDay, setSelectedDay] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>("");

  const handleClickDay = (day: string) => {
    if (selectedDay.includes(day)) {
      setSelectedDay(selectedDay.filter((selected) => selected !== day));
    } else {
      setSelectedDay([...selectedDay, day]);
    }
  };

  const thing = useReservationRule(Number(thingId));
  const { mutateAsync: mutateRegisterVacancyAlarm } = useRegisterVacancyAlarm();
  const { setModal } = useModalStore();

  const timeTable: string[] = useMemo(() => {
    if (!thing) return [];
    const { morning, afternoon } = createTimeTableWithStartAndEnd(
      thing.availStartTime,
      thing.availEndTime,
      thing.intervalMin
    );
    return [
      ...morning.map((time) => `${time.startTime} - ${time.endTime}`),
      ...afternoon.map((time) => `${time.startTime} - ${time.endTime}`),
    ];
  }, [thing]);

  useEffect(() => {
    if (!timeTable) return;
    setSelectedTime(timeTable[0]);
  }, [timeTable]);

  const checkIsValidate = () => {
    if (selectedDay.length === 0) {
      setModal({
        modalType: "error",
        headerTitle: "요일을 선택해주세요",
        mainContent: [""],
      });
      window.location.assign("#error");
      return false;
    }

    if (selectedTime === "") {
      setModal({
        modalType: "error",
        headerTitle: "시간을 선택해주세요",
        mainContent: [""],
      });
      window.location.assign("#error");
      return false;
    }
    return true;
  };

  const registerVacancyAlarm = async () => {
    const time = selectedTime.split(" - ");

    await mutateRegisterVacancyAlarm({
      thingId: Number(thingId),
      days: selectedDay,
      startTime: time[0],
      endTime: time[1],
    }).then(() => {
      setSelectedDay([]);
      setSelectedTime(timeTable[0]);
    });
  };

  const handleClickRegisterBtn = () => {
    if (!checkIsValidate()) {
      return;
    }

    const time = selectedTime.split(" - ");

    setModal({
      modalType: "confirm",
      headerTitle: "등록하시겠습니까?",
      mainContent: [
        `요일 : ${selectedDay.map((day) => Days[day]).join(", ")}`,
        `시간 : ${time[0]} - ${time[1]}`,
      ],
      modalAction: () => {
        registerVacancyAlarm();
      },
    });

    window.location.assign("#confirm");
  };

  return (
    <VacancyAlarmFormWrapper>
      <InfoMessage>
        <Typography fontSize={isMobile ? 18 : 20}>
          🔔 알림 등록하기 🔔
        </Typography>
        <Typography fontSize={isMobile ? 12 : 16} color="#7E7E7E">
          요일과 시간을 선택해주세요
        </Typography>
      </InfoMessage>
      <FormWrapper>
        <DayChipWrapper>
          {thing?.availDay === EAvailDay.WEEKDAYS ? (
            <>
              {Object.keys(Weekdays).map((day: string) => (
                <RoundChip
                  className={selectedDay.includes(day) ? "check" : ""}
                  size={isMobile ? "m" : "l"}
                  label={Weekdays[day]}
                  onClick={() => handleClickDay(day)}
                />
              ))}
            </>
          ) : thing?.availDay === EAvailDay.WEEKEND ? (
            <>
              {Object.keys(Weekends).map((day: string) => (
                <RoundChip
                  className={selectedDay.includes(day) ? "check" : ""}
                  size={isMobile ? "m" : "l"}
                  label={Weekends[day]}
                  onClick={() => handleClickDay(day)}
                />
              ))}
            </>
          ) : (
            <>
              {Object.keys(Days).map((day: string) => (
                <RoundChip
                  className={selectedDay.includes(day) ? "check" : ""}
                  size={isMobile ? "m" : "l"}
                  label={Days[day]}
                  onClick={() => handleClickDay(day)}
                />
              ))}
            </>
          )}
        </DayChipWrapper>
        <SelectWrapper>
          <Select
            value={selectedTime}
            sx={{
              height: isMobile ? "30px" : "40px",
              width: "auto",
              fontSize: isMobile ? "14px" : "16px",
              minWidth: "140px",
            }}
            onChange={(e) => setSelectedTime(e.target.value as string)}
            displayEmpty
          >
            {timeTable.length > 0 &&
              timeTable.map((time) => (
                <MenuItem
                  key={time}
                  value={time}
                  sx={{
                    minHeight: "30px",
                    width: "auto",
                    fontSize: isMobile ? "14px" : "16px",
                  }}
                >
                  {time}
                </MenuItem>
              ))}
          </Select>
          <Typography fontSize={16}>에 빈자리 알림 받기</Typography>
        </SelectWrapper>
        <ButtonWrapper>
          <Button
            variant="contained"
            color="secondaryBlue"
            btnsize={30}
            onClick={handleClickRegisterBtn}
          >
            등록
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </VacancyAlarmFormWrapper>
  );
};
