import { useMutation } from "@tanstack/react-query";
import Api from "../../../services/ApiService";
import {
  ReissueAtkRequestType,
  ReissueAtkResponseType,
} from "../../../services";
import { CLIENT_DEVICE_ID_KEY } from "../../../common/commonContants";

export const reissueToken = async () => {
  const clientDeviceId = localStorage.getItem(CLIENT_DEVICE_ID_KEY);
  const response = await Api.post<
    ReissueAtkResponseType,
    ReissueAtkRequestType
  >("/auth/reissue", { clientDeviceId });
  return response;
};

export const useReissueToken = () => {
  const {
    data: response,
    isSuccess,
    isError,
    error,
    mutate,
  } = useMutation(() => reissueToken(), { useErrorBoundary: true });

  return { response, isError, error, isSuccess, mutate };
};
