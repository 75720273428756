import { FC, useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import {
  styled,
  Grid,
  Box,
  useMediaQuery,
  Theme,
  Typography,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReservationMainProps } from "./ReservationMain.type";
import {
  MobileReservationContent,
  WebReservationContent,
} from "../../organisms";
import {
  ReservationInfo,
  TimeTable,
  TimeTableDetail,
} from "../../../model/ReservationType";
import { useReservationStatus } from "../../../pages/ReservationPage/hooks/useReservationStatus";
import { useModalStore, useReservationStore } from "../../../zustand";
import { useReservationMenu } from "../../../pages/ReservationPage/hooks/useReservationThing";
import Format from "../../../services/FormatService";
import { EAvailDay } from "../../../common/commonContants";
import { createTimeTableWithStartAndEnd } from "../../../common/utils/timeTableUtils";

const ReservationWrapper = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  minHeight: "calc(100vh - 300px)",
  width: "100%",

  [theme.breakpoints.up("laptop")]: {
    minHeight: "calc(100vh + 100px)",
  },
}));

const DatePickerWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    marginRight: "50px",
    display: "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.down("laptop")]: {
    marginTop: "60px",
    // overflowY: "scroll",
    // position: "absolute",
    // top: "70px",
    // height: "calc(100vh - 50%)",
  },
}));

const CustomDatePicker = styled(StaticDatePicker<any>)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    marginTop: "50px",
  },

  "& .MuiTypography-caption": {
    [theme.breakpoints.up("laptop")]: {
      width: 50,
      margin: 0,
    },
    width: 40,
    margin: 0,
  },
  "& .MuiTypography-root": {
    [theme.breakpoints.up("laptop")]: {
      fontSize: 18,
      margin: 2,
    },
    fontSize: 14,
    margin: 0.3,
  },
  "& .MuiPickersSlideTransition-root": {
    minHeight: 45 * 7,
  },
  "& .MuiDateCalendar-root": {
    minHeight: 45 * 9,
  },
  "& .MuiPickersDay-root": {
    [theme.breakpoints.up("laptop")]: {
      fontSize: 18,
      margin: 2,
    },
    width: 40,
    height: 40,
    fontSize: 14,
    margin: 0.3,
  },
  "& .MuiPickersCalendarHeader-root": {
    paddingLeft: 12,
  },
  "& .MuiPickersCalendarHeader-label": {
    [theme.breakpoints.up("laptop")]: {
      fontSize: 20,
    },
    fontSize: 16,
  },
}));

export const ReservationMain: FC<ReservationMainProps> = ({
  selectedThing,
  thingId,
}) => {
  // zustand
  const {
    selectedDate,
    selectedTime,
    setSelectedDate,
    setIsReserved,
    setSelectedInfo,
    setSelectedTime,
  } = useReservationStore();
  // react-query
  const { setModalType, setHeaderTitle, setMainContent } = useModalStore();
  const { reservations, updateDate } = useReservationStatus(thingId);
  const { response: menu } = useReservationMenu(thingId, true);
  // for DatePicker
  const formats = {
    monthAndYear: "YYYY.MM",
  };

  const maxDate = dayjs().add(selectedThing.availReserveDayBefore, "day");

  const [timeTable, setTimeTable] = useState<TimeTable>();
  const handleDateChange = (newDate: Date) => {
    setSelectedDate(newDate);
    updateDate(dayjs(newDate));
    setIsReserved(false);
    setSelectedInfo(undefined);
    setSelectedTime({ startTime: "", endTime: "" });
  };

  const mobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("laptop")
  );

  const checkDisableDate = useCallback(
    (date: Date) => {
      const checkDate = new Date(date);
      if (selectedThing.availDay === EAvailDay.WEEKDAYS)
        return checkDate.getDay() === 0 || checkDate.getDay() === 6;
      if (selectedThing.availDay === EAvailDay.WEEKEND)
        return !(checkDate.getDay() === 0 || checkDate.getDay() === 6);
      return false;
    },
    [selectedThing]
  );

  const createTimeTable = useCallback(() => {
    if (selectedDate && checkDisableDate(selectedDate)) {
      return;
    }
    const { morning, afternoon } = createTimeTableWithStartAndEnd(
      selectedThing?.availStartTime,
      selectedThing?.availEndTime,
      selectedThing?.intervalMin
    );

    setTimeTable({ morning, afternoon });
  }, [selectedThing, selectedDate]);

  const checkIsReserved = (time: TimeTableDetail): void => {
    setIsReserved(false);
    reservations?.reservedDtoList.forEach((reserve: ReservationInfo) => {
      if (reserve.startTime.slice(0, 5) === time.startTime) {
        setIsReserved(true);
        setSelectedInfo(reserve);
      }
    });
  };

  const handleReservationBtnClick = () => {
    setHeaderTitle("예약하시겠습니까?");
    setMainContent([
      `일자 : ${selectedDate ? Format.dateToKoreanDate(selectedDate) : "-"}`,
      `시간 : ${selectedTime.startTime} - ${selectedTime.endTime}`,
    ]);
    setModalType("reserve");
    window.location.assign("#confirm");
  };

  const curDate = new Date();
  const currentTime = dayjs(dayjs(), "HH:mm");

  const disableButton = (time: TimeTableDetail): boolean => {
    const checkIsReserved = reservations?.reservedDtoList.findIndex(
      ({ startTime }) => startTime.slice(0, 5) === time.startTime
    );

    if (checkIsReserved !== -1) {
      return true;
    }

    if (selectedDate) {
      const newDate = new Date(selectedDate);
      if (
        newDate.getDate() === curDate.getDate() &&
        currentTime >= dayjs(time.startTime, "HH:mm").add(20, "minute")
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    createTimeTable();
  }, [reservations, selectedThing]);

  return (
    <ReservationWrapper
      container
      direction={mobileScreen ? "column" : "row"}
      columnGap={mobileScreen ? 2 : 0}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={formats}>
        <DatePickerWrapper>
          {!mobileScreen && (
            <Typography fontSize={22}>{menu ? menu.name : ""}</Typography>
          )}
          <CustomDatePicker
            views={["day"]}
            slots={{
              toolbar: undefined,
            }}
            slotProps={{
              actionBar: { sx: { display: "none" } },
              day: {
                sx: {
                  "&.Mui-selected": {
                    backgroundColor: "selectGreen.main",
                    ":focus": {
                      backgroundColor: "selectGreen.main",
                    },
                    ":hover": {
                      backgroundColor: "selectGreen.main",
                    },
                  },
                },
                disableRipple: true,
                disableTouchRipple: true,
              },
            }}
            onChange={handleDateChange}
            value={dayjs(selectedDate)}
            disablePast
            defaultValue={dayjs(new Date())}
            maxDate={maxDate}
            shouldDisableDate={checkDisableDate}
          />
        </DatePickerWrapper>
      </LocalizationProvider>
      {mobileScreen ? (
        <MobileReservationContent
          timeTable={timeTable}
          reservations={reservations}
          checkIsReserved={checkIsReserved}
          handleReservationBtnClick={handleReservationBtnClick}
          isBtnDisabled={disableButton}
        />
      ) : (
        <WebReservationContent
          timeTable={timeTable}
          reservations={reservations}
          checkIsReserved={checkIsReserved}
          handleReservationBtnClick={handleReservationBtnClick}
          isBtnDisabled={disableButton}
        />
      )}
    </ReservationWrapper>
  );
};
