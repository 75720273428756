import { FC } from "react";
import { Box, Typography, styled } from "@mui/material";
import { ReservationProfileType } from "./ReservationProfile.type";
import { Avatar } from "../../atoms";

const ProfileWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    width: "400px",
    height: "75px",
  },
  width: "300px",
  height: "60px",
  borderRadius: "8px",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledTypograpy = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    fontSize: "20px",
    marginLeft: "30px",
  },
  fontSize: "16px",
  marginLeft: "20px",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    marginLeft: "30px",
  },
  marginLeft: "20px",
}));

export const ReservationProfile: FC<ReservationProfileType> = ({
  imgUrl,
  name,
  size,
}) => (
  <ProfileWrapper>
    <StyledAvatar src={imgUrl} size={size} />
    <StyledTypograpy>{name}</StyledTypograpy>
  </ProfileWrapper>
);
