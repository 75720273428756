import { FC } from "react";
import { Grid, Theme, styled, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ImageCard } from "../../molecules";
import { ImageCardListProps } from "./ImageCardList.type";
import { MenuType } from "../../../model/MenuType";

const MainPageWrapper = styled(Grid)({
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

export const ImageCardList: FC<ImageCardListProps> = ({ menuList }) => {
  const navigate = useNavigate();
  const mobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("laptop")
  );

  const handleImageBtnClick = (menu: MenuType) => {
    navigate(`/reservation?thingId=${menu.thingId}`);
  };

  return (
    <MainPageWrapper container direction="row" columnGap={mobileScreen ? 2 : 4}>
      {menuList.map((menu: MenuType) => (
        <ImageCard
          src={menu.imageUrl}
          title={menu.name}
          key={menu.name}
          onClick={() => handleImageBtnClick(menu)}
        />
      ))}
    </MainPageWrapper>
  );
};
