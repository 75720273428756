import { useQuery } from "@tanstack/react-query";
import { MenuType } from "../../../model/MenuType";
import Api from "../../../services/ApiService";
import { useThingStore } from "../../../zustand";
import { queryKeys } from "../../../react-query";
import { useIsAuthenticated } from "../../AuthRequiredApp/hooks/useIsAuthenticated";

async function getReservationMenu(thingId: number): Promise<MenuType> {
  const { data } = await Api.get<MenuType>(`/thing/${thingId}`);
  return data;
}

export const useReservationMenu = (thingId: number, enabled: boolean) => {
  const { selectedThing } = useThingStore();
  const isAuthenticated = useIsAuthenticated();
  const { data: response, refetch } = useQuery<MenuType>(
    [queryKeys.menu, thingId],
    () =>
      getReservationMenu(
        selectedThing?.thingId ? selectedThing.thingId : thingId
      ),
    {
      enabled: isAuthenticated && enabled,
    }
  );
  return { response, refetch };
};
