import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { queryKeys } from "../../../react-query/queryKeys";
import Api from "../../../services/ApiService";
import { RetrieveTokenResponseType } from "../SlackLoginHandlerPage.type";
import { useAuthStore } from "../../../zustand";
import { CLIENT_DEVICE_ID_KEY } from "../../../common/commonContants";

const retrieveTokens = async (authCode: string | null) => {
  const response = await Api.get<RetrieveTokenResponseType>("/auth/login", {
    code: authCode,
  });

  return response;
};

export const useSlackLogin = (authCode: string | null) => {
  const navigate = useNavigate();
  const setIsLoggedIn = useAuthStore((state) => state.setIsLoggedIn);
  const setAtk = useAuthStore((state) => state.setAtk);
  const {
    data: response,
    isSuccess,
    isError,
  } = useQuery([queryKeys.login], () => retrieveTokens(authCode));

  useEffect(() => {
    if (isSuccess && response?.data) {
      localStorage.setItem(CLIENT_DEVICE_ID_KEY, response?.data.clientDeviceId);
      setIsLoggedIn(true); // NOTE AuthRequiredApp 컴포 제외 유일하게 로그인 상태값을 바꿀 수 있는 곳으로 해야할듯
      setAtk(response?.data.accessToken);
      if (response?.data.isFirst) {
        navigate("/signup");
      } else {
        navigate("/");
      }
    }
  }, [response?.data, isSuccess]);

  return { response, isSuccess, isError };
};
