import { useMutation, useQueryClient } from "@tanstack/react-query";
import Api from "../../../services/ApiService";
import { TeamType, UserInfoResponse } from "../../AdditionalInfoMainPage";
import { mutationErrorHandler } from "../../../react-query";

export type UpdatedUserInfo = {
  nickname: string;
  team: TeamType;
};

const updateProfile = async (updatedUserInfo: UpdatedUserInfo) => {
  const response = await Api.put<null>("/member/info", {
    nickname: updatedUserInfo.nickname,
    teamId: updatedUserInfo.team.teamId,
  });

  return response;
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  const {
    data: response,
    isError,
    isSuccess,
    error,
    mutate,
    mutateAsync,
  } = useMutation({
    mutationFn: (updatedUserInfo: UpdatedUserInfo) =>
      updateProfile(updatedUserInfo),
    onMutate: async (userInfo) => {
      await queryClient.cancelQueries({ queryKey: ["userInfo"] });
      const previousInfo = queryClient.getQueryData(["userInfo"]);

      queryClient.setQueryData(
        ["userInfo"],
        (previous: UserInfoResponse | undefined) => ({
          ...previous,
          nickName: userInfo.nickname,
          team: userInfo.team,
        })
      );
      return { previousInfo, userInfo };
    },
    onError: (err, _userInfo, context) => {
      mutationErrorHandler(err);
      queryClient.setQueryData(["userInfo"], context?.previousInfo);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userInfo"] });
    },
  });
  return { response, isError, isSuccess, error, mutate, mutateAsync };
};
