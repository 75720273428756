import { FC } from "react";
import { Box, Theme, Typography, styled, useMediaQuery } from "@mui/material";

import { ImageCardProps } from "./ImageCard.type";
import { Image } from "../../atoms";

const ImageCardWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    width: "188px",
    height: "250px",
  },
  width: "94px",
  height: "150px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  borderRadius: "10px",
  backgroundColor: theme.palette.grey[100],
  position: "relative",
}));

const ImageCardTitleWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    bottom: "20px",
    width: "188px",
    height: "60px",
  },
  position: "absolute",
  bottom: "10px",
  width: "65px",
  height: "50px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledTypograpy = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    fontSize: "22px",
  },
  fontSize: "17px",
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    marginBottom: "25px",
    top: "50px",
  },
  marginBottom: "15px",
  top: "25px",
  position: "absolute",
}));

export const ImageCard: FC<ImageCardProps> = ({ title, src, onClick }) => {
  const mobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("laptop")
  );

  const titleArr = title.split(" ");

  return (
    <ImageCardWrapper onClick={onClick}>
      <ImageWrapper>
        <Image src={src} width={!mobileScreen ? "100px" : "50px"} />
      </ImageWrapper>

      <ImageCardTitleWrapper>
        {titleArr.map((t) => (
          <StyledTypograpy key={t}>{t}</StyledTypograpy>
        ))}
      </ImageCardTitleWrapper>
    </ImageCardWrapper>
  );
};
