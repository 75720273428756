import { FC } from "react";
import {
  Box,
  styled,
  Typography,
  Grid,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { ReservationCompleteMainProps } from "./ReservationCompleteMain.type";
import { Image, Button } from "../../atoms";
import { ReservationCompleteCard } from "../../molecules";

const ReservationCompleteWrapper = styled(Grid)({
  justifyContent: "center",
  alignItems: "center",
  minHeight: "calc(100vh + 70px)",
  width: "100%",
});

const ConfirmMessage = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    margin: theme.spacing(5, "auto"),
    fontSize: "20px",
  },
  margin: theme.spacing(4, "auto"),
  fontSize: "16px",
}));

const ConfirmMessageBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    margin: theme.spacing(8, "auto"),
  },
  margin: theme.spacing(7, "auto"),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const ConfirmInfoMessage = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    fontSize: "18px",
    lineHeight: "30px",
  },
  fontSize: "15px",
  color: theme.palette.grey[400],
}));

const ButtonWrapper = styled(Box)(() => ({
  justifyContent: "center",
  alignItems: "center",
}));

export const ReservationCompleteMain: FC<ReservationCompleteMainProps> = () => {
  const qs = queryString.parse(window.location.search);
  const mobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("laptop")
  );

  const navigate = useNavigate();

  return (
    <ReservationCompleteWrapper container direction="column" rowSpacing={2}>
      <Image
        src="/assets/img/img_check.svg"
        alt="check img"
        width={mobileScreen ? "auto" : "100"}
      />
      <ConfirmMessage>{qs.nickName}님, 예약이 완료되었어요!</ConfirmMessage>
      <ReservationCompleteCard
        reserveDate={qs.reserveDate}
        startTime={qs.startTime}
        endTime={qs.endTime}
      />
      <ConfirmMessageBox>
        <ConfirmInfoMessage>
          {qs.nickName}님, 편안한 하루 되세요!
        </ConfirmInfoMessage>
        <ConfirmInfoMessage>
          쾌적한 사용을 위해 입장 시간을 꼭 지켜주세요!
        </ConfirmInfoMessage>
      </ConfirmMessageBox>
      <ButtonWrapper>
        <Button
          variant="contained"
          color="secondaryBlue"
          btnsize={mobileScreen ? 30 : 60}
          onClick={() => navigate("/")}
        >
          메인으로
        </Button>
        <Button
          variant="contained"
          color="primaryPink"
          btnsize={mobileScreen ? 30 : 60}
          sx={{ ml: `${mobileScreen ? 5 : 15}PX` }}
          onClick={() => navigate("/mypage")}
        >
          마이페이지
        </Button>
      </ButtonWrapper>
    </ReservationCompleteWrapper>
  );
};
