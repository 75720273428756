import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { ErrorHandlingComponent } from "./ErrorHandlingComponent";
// import { CommonResponse } from "../../../services";
// import { ReserverStatusCode } from "../ReserveStatusCode.type";
import { useAuthStore } from "../../../zustand";

export interface AxiosErrorHandlingComponentProps {
  // eslint-disable-next-line react/no-unused-prop-types
  error: AxiosError;
  resetErrorBoundary: () => void;
}

export const AxiosErrorHandlingComponent = ({
  resetErrorBoundary,
}: AxiosErrorHandlingComponentProps) => {
  let message;
  const setIsLoggedIn = useAuthStore((state) => state.setIsLoggedIn);
  const setAtk = useAuthStore((state) => state.setAtk);
  const navigate = useNavigate();

  const onClick = () => {
    resetErrorBoundary();
    setIsLoggedIn(false);
    setAtk(null);
    navigate("/");
  };

  // switch ((error.response?.data as CommonResponse<null>).statusCode) {
  //   default:
  //     message = ["서버와 통신 중 에러가 발생했습니다.", "메인으로 돌아갑니다."];
  //     onClick = () => {
  //       resetErrorBoundary();
  //       navigate("/");
  //     };
  // }

  return (
    <ErrorHandlingComponent
      message={message}
      chipButton={{ label: "메인으로", onClick }}
    />
  );
};
