import { FC } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  Typography,
  styled,
} from "@mui/material";
import { ReservationContentProps } from "./ReservationContent.type";
import { ReservationProfile, WebTimeTable } from "../../molecules";
import { Button } from "../../atoms";
import { useReservationStore } from "../../../zustand";

const ReservationContentWrapper = styled(List)({
  height: "500px",
  width: "550px",
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "550px",
  marginTop: "20px",
});

export const WebReservationContent: FC<ReservationContentProps> = ({
  timeTable,
  checkIsReserved,
  handleReservationBtnClick,
  isBtnDisabled,
}) => {
  const { selectedTime, isReserved, selectedInfo } = useReservationStore();

  return (
    <ReservationContentWrapper>
      <ListItem>
        {timeTable && (
          <WebTimeTable
            timeTable={timeTable}
            isBtnDisabled={isBtnDisabled}
            checkReserveState={checkIsReserved}
          />
        )}
      </ListItem>
      <Divider textAlign="left" sx={{ marginTop: "20px" }}>
        예약 현황
      </Divider>
      <ListItem>
        <ButtonWrapper>
          {selectedTime.startTime === "" && (
            <Typography>예약 시간을 선택해주세요.</Typography>
          )}
          {isReserved && selectedInfo && (
            <ReservationProfile
              name={selectedInfo.nickname}
              imgUrl={selectedInfo.profileImageUrl}
              size="m"
            />
          )}
          {selectedTime.startTime !== "" && !isReserved && (
            <Button
              variant="contained"
              btnsize={60}
              color="secondaryBlue"
              onClick={handleReservationBtnClick}
            >
              예약하기
            </Button>
          )}
        </ButtonWrapper>
      </ListItem>
    </ReservationContentWrapper>
  );
};
