import { useQuery } from "@tanstack/react-query";
import Api from "../../../services/ApiService";
import { queryKeys } from "../../../react-query/queryKeys";
import { useIsAuthenticated } from "../../AuthRequiredApp/hooks/useIsAuthenticated";

export interface TeamType {
  teamId: number;
  teamName: string;
}

export interface CenterType {
  centerId: number;
  centerName: string;
}
export interface UserInfoResponse {
  nickName?: string;
  email?: string;
  profileImgUrl?: string;
  center?: CenterType;
  team?: TeamType;
  first?: boolean;
}

const getUserInfo = async (): Promise<UserInfoResponse> => {
  const response = await Api.get<UserInfoResponse>("/member/info", {});

  return response.data;
};

export const useUserInfo = () => {
  const isAuthenticated = useIsAuthenticated();
  const {
    data: response,
    isSuccess,
    isError,
  } = useQuery<UserInfoResponse>({
    queryKey: [queryKeys.userInfo],
    queryFn: () => getUserInfo(),
    enabled: isAuthenticated,
  });

  return { response, isSuccess, isError };
};
