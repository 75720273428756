import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimeTableDetail } from "../../model/ReservationType";

export const createTimeTableWithStartAndEnd = (
  availStartTime: string,
  availEndTime: string,
  intervalMin: number
): { morning: TimeTableDetail[]; afternoon: TimeTableDetail[] } => {
  const morning: TimeTableDetail[] = [];
  const afternoon: TimeTableDetail[] = [];

  dayjs.extend(customParseFormat);
  let startTime = dayjs(availStartTime, "HH:mm:ss");
  const lastTime = dayjs(availEndTime, "HH:mm:ss");
  while (startTime < dayjs("12:00:00", "HH:mm:ss")) {
    const endTime = startTime.add(intervalMin, "minute");
    morning.push({
      startTime: startTime.format("HH:mm").toString(),
      endTime: endTime.format("HH:mm").toString(),
    });

    startTime = endTime;
  }

  while (startTime <= lastTime) {
    const endTime = startTime.add(intervalMin, "minute");
    afternoon.push({
      startTime: startTime.format("HH:mm").toString(),
      endTime: endTime.format("HH:mm").toString(),
    });

    startTime = endTime;
  }

  return { morning, afternoon };
};
