import { MyPageTemplate } from "../../components";
import { useMyPageReservationInfo } from "./hooks";

export const MyPage = () => {
  const {
    response: myPageReservationInfoResponse,
    isSuccess: myPageReservationInfoIsSuccess,
  } = useMyPageReservationInfo();

  if (myPageReservationInfoIsSuccess && myPageReservationInfoResponse?.data) {
    return (
      <MyPageTemplate
        scheduledReservation={
          myPageReservationInfoResponse?.data.scheduledReservation
        }
        monthlyReservation={
          myPageReservationInfoResponse?.data.monthlyReservation
        }
      />
    );
  }
  return <div />;
};
