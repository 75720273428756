import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Api from "../../../services/ApiService";
import { UpdateMemberTeamAtSignInReqType } from "./useUpdateMemberTeamAtSignIn.type";

const updateMemberTeamAtSignIn = async ({
  name,
}: UpdateMemberTeamAtSignInReqType) => {
  const response = await Api.put<null>("/auth/enroll/team", {
    name,
  });

  return response;
};

export const useUpdateMemberTeamAtSignIn = () => {
  const navigate = useNavigate();
  const {
    data: response,
    isError,
    isSuccess,
    error,
    mutate,
  } = useMutation((name: string) => updateMemberTeamAtSignIn({ name }), {
    onSuccess: () => {
      navigate("/");
    },
  });

  return { response, isError, isSuccess, error, mutate };
};
