import { Box, styled, Typography } from "@mui/material";
import { FC } from "react";
import { ReservationCard } from "../../molecules/ReservationCard";
import { ReservatoinCarouselProps } from "./ReservationCarousel.type";

const CarouselWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.up("laptop")]: {
    padding: "1rem 0",
  },
  overflowX: "auto",
  position: "relative",
  width: "calc(100% + 20px)",
}));

const CarouselContentWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    marginRight: "10px",
  },
  [theme.breakpoints.up("tablet")]: {
    marginRight: "20px",
  },
}));

export const ReservationCarousel: FC<ReservatoinCarouselProps> = ({
  scheduledReservation,
}) => (
  <CarouselWrapper>
    {scheduledReservation && scheduledReservation.length > 0 ? (
      scheduledReservation.map((reservation) => (
        <CarouselContentWrapper
          key={
            reservation.reservationDate +
            reservation.reservationStartTime +
            reservation.thingId
          }
        >
          <ReservationCard
            thingId={reservation.thingId}
            reservationDate={reservation.reservationDate}
            reservationStartTime={reservation.reservationStartTime}
            reservationEndTime={reservation.reservationEndTime}
            thingImageUrl={reservation.thingImageUrl}
            reservationLabel={reservation.reservationLabel}
          />
        </CarouselContentWrapper>
      ))
    ) : (
      <Typography fontSize="14px"> 예정된 예약이 없습니다.</Typography>
    )}
  </CarouselWrapper>
);
