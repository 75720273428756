import { FC, useEffect } from "react";
import queryString from "query-string";
import { ReservationMain } from "../../components";
import { ReservationPageProps } from "./ReservationPage.type";
import { useReservationRule } from "./hooks/useReservationRule";

import { useReservationStore, useThingStore } from "../../zustand";

export const ReservationPage: FC<ReservationPageProps> = () => {
  const qs = queryString.parse(window.location.search);

  const { setSelectedThing, selectedThing } = useThingStore();
  const { reset } = useReservationStore();

  const { thingId } = qs;
  const thing = useReservationRule(Number(thingId));

  useEffect(() => {
    setSelectedThing(thing);
  }, [thingId, thing]);

  useEffect(() => {
    reset();
  }, []);

  return (
    <div>
      {selectedThing && (
        <ReservationMain
          selectedThing={selectedThing}
          thingId={thingId ? Number(thingId) : selectedThing.thingId}
        />
      )}
    </div>
  );
};
