import { FC } from "react";
import { Select as MuiSelect, SxProps, useTheme } from "@mui/material";
import { SelectProps } from "./Select.type";
import { ReactComponent as ArrowDownIcon } from "../../../assets/img/ico_down_arrow.svg";

export const Select: FC<SelectProps> = ({ children, sx, ...rest }) => {
  const theme = useTheme();

  const defaultSx: SxProps = {
    [theme.breakpoints.up("laptop")]: {
      width: "300px",
      height: "56px",
      fontSize: "20px",
    },
    width: "180px",
    height: "36px",
    fontSize: "17px",
    border: theme.palette.grey[300],
    color: theme.palette.grey[300],
    "&.MuiOutlinedInput-root": {
      ".Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.grey[300]}`,
      },
      ".MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    },
  };

  return (
    <MuiSelect
      {...rest}
      size="small"
      IconComponent={ArrowDownIcon}
      MenuProps={{
        sx: {
          "& .Mui-selected": {
            backgroundColor: "#DDDDDD",
            "&: hover": {
              backgroundColor: "#DDDDDD",
            },
          },
        },
      }}
      sx={{ defaultSx, ...sx }}
    >
      {children}
    </MuiSelect>
  );
};

export default Select;
