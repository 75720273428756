import { FC, useState } from "react";
import { styled, Box, Grid } from "@mui/material";

import { TimeTableType } from "./TimeTable.type";
import {
  ChipButton,
  Tab,
  TabWrapper,
  TabPanel,
  a11yProps,
  Tabs,
} from "../../atoms";
import { useReservationStore } from "../../../zustand";
import { TimeTableDetail } from "../../../model/ReservationType";

const ReservationContentWrapper = styled(Box)(() => ({
  height: "270px",
  width: "100%",
}));

const TimeTableWrapper = styled(Grid)({});

export const WebTimeTable: FC<TimeTableType> = ({
  timeTable,
  checkReserveState,
  isBtnDisabled,
}) => {
  const currentDate = new Date();
  const [value, setValue] = useState(currentDate.getHours() >= 12 ? 1 : 0);
  const { selectedTime, setSelectedTime, setIsReserved } =
    useReservationStore();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsReserved(false);
    setSelectedTime({ startTime: "", endTime: "" });
  };

  const handleChipBtnClick = (time: TimeTableDetail) => {
    setSelectedTime(time);
    checkReserveState(time);
  };

  return (
    <ReservationContentWrapper>
      <TabWrapper>
        <Tabs
          value={value}
          onChange={handleChange}
          ara-aria-label="activities tabs"
        >
          <Tab label="오전" {...a11yProps(0)} />
          <Tab label="오후" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <TimeTableWrapper container columnGap={1} rowGap={1}>
            {timeTable.morning.map((time: TimeTableDetail) => (
              <ChipButton
                className={
                  selectedTime.startTime === time.startTime ? "check" : ""
                }
                onClick={() => handleChipBtnClick(time)}
                label={`${time.startTime} - ${time.endTime}`}
                key={time.startTime}
                disabled={isBtnDisabled(time)}
              />
            ))}
          </TimeTableWrapper>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TimeTableWrapper container columnGap={1} rowGap={1}>
            {timeTable.afternoon.map((time: TimeTableDetail) => (
              <ChipButton
                className={
                  selectedTime.startTime === time.startTime ? "check" : ""
                }
                onClick={() => handleChipBtnClick(time)}
                label={`${time.startTime} - ${time.endTime}`}
                key={time.startTime}
                disabled={isBtnDisabled(time)}
              />
            ))}
          </TimeTableWrapper>
        </TabPanel>
      </TabWrapper>
    </ReservationContentWrapper>
  );
};
