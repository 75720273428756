import { useMutation } from "@tanstack/react-query";
import Api from "../../../services/ApiService";
import { UseLogoutRequestBody } from "./useLogout.type";
import { CLIENT_DEVICE_ID_KEY } from "../../../common/commonContants";

const logout = async () => {
  const clientDeviceId = localStorage.getItem(CLIENT_DEVICE_ID_KEY);
  if (!clientDeviceId) {
    throw new Error("no refresh token or clientDeviceId found in localStorage");
  }

  const response = await Api.post<null, UseLogoutRequestBody>("/auth/logout", {
    clientDeviceId,
  });

  return response;
};

export const useLogout = () => {
  const { isError, isSuccess, mutate } = useMutation(() => logout(), {
    useErrorBoundary: true,
    onSettled: () => {
      localStorage.removeItem(CLIENT_DEVICE_ID_KEY);
      window.location.href = "/";
    },
  });

  return { isSuccess, isError, mutate };
};
