import { FC } from "react";
import { DialogActions as MuiDialogActions } from "@mui/material";
import { DialogActionsProps } from "./DialogAction.type";

export const DialogActions: FC<DialogActionsProps> = ({
  children,
  ...rest
}) => <MuiDialogActions {...rest}>{children}</MuiDialogActions>;

export default DialogActions;
