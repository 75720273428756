import { styled } from "@mui/material/styles";
import { ImageProps } from "./Image.type";

const ImageView = styled(({ ...rest }: ImageProps) => (
  <img loading="lazy" {...rest} />
))(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ({ ...rest }) => {
    const { objectFit } = rest;
    return {
      maxWidth: "100%",
      objectFit,
    };
  }
);

const defaultProps = {
  src: "",
  alt: "no-img-data",
  width: "auto",
  height: "auto",
  loading: "lazy",
};

export const Image = (props: ImageProps & typeof defaultProps) => (
  <ImageView {...props} />
);

Image.defaultProps = defaultProps;

export default Image;
