import { useMutation, useQueryClient } from "@tanstack/react-query";
import Api from "../../../services/ApiService";
import { queryKeys } from "../../../react-query";

const cancelVacancyAlarm = async (alarmId: number) => {
  await Api.delete(`/vacancy-alarm/${alarmId}`);
};

export const useCancelVacancyAlarm = () => {
  const queryClient = useQueryClient();

  const { isError, isSuccess, error, mutate, mutateAsync } = useMutation(
    cancelVacancyAlarm,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.vacancyAlarm]);
      },
    }
  );

  return { isError, isSuccess, error, mutate, mutateAsync };
};
