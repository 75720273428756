import { styled } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { StyledButtonProps } from "./Button.type";

export const StyledButton = styled(MuiButton)<StyledButtonProps>(
  ({ btnsize }) => {
    const br = 100; // borderRadius
    const bh: number = btnsize || 18; // height
    let bw = 100;
    let fw = 400; // fontWeight
    let fs = 16; // fontSize
    let lh = 16; // lineHeight
    const ls = 0; // letterSpacing

    switch (btnsize) {
      case 30: {
        bw = 90;
        fw = 400;
        fs = 14;
        lh = 20;
        break;
      }
      case 40: {
        bw = 120;
        fw = 400;
        fs = 16;
        lh = 18;
        break;
      }
      case 54: {
        bw = 150;
        fw = 400;
        fs = 16;
        lh = 20;
        break;
      }
      case 60: {
        bw = 165;
        fw = 400;
        fs = 20;
        lh = 20;
        break;
      }
      case 70: {
        bw = 250;
        fw = 400;
        fs = 20;
        lh = 20;
        break;
      }
      default: {
        bw = 90;
        fw = 400;
        fs = 14;
        lh = 20;
        break;
      }
    }

    return {
      width: `${bw}px`,
      height: `${bh}px`,
      border: "none",
      borderRadius: `${br}px`,
      fontWeight: fw,
      fontSize: `${fs}px`,
      lineHeight: `${lh}px`,
      letterSpacing: `${ls}px`,
      flex: "0 0 auto",
      boxShadow: "none",
      padding: "0",
      color: "#ffffff",
    };
  }
);

export const Button = ({ btnsize, children, ...rest }: StyledButtonProps) => (
  <StyledButton disableRipple btnsize={btnsize} {...rest}>
    {children}
  </StyledButton>
);

export default Button;
