import { styled, Avatar as MuiAvatar } from "@mui/material";
import { FC } from "react";
import { AvatarProps } from "./Avatar.type";

const MuiAvatarView = styled(MuiAvatar)<AvatarProps>(({ size }) => {
  let width = 24;
  let height = 24;

  switch (size) {
    case "s": {
      width = 40;
      height = 40;
      break;
    }
    case "m": {
      width = 50;
      height = 50;
      break;
    }
    case "l": {
      width = 70;
      height = 70;
      break;
    }
    default: {
      width = 24;
      height = 24;
    }
  }
  return {
    boarder: 0,
    width: `${width}px`,
    height: `${height}px`,
  };
});

export const Avatar: FC<AvatarProps> = ({ children, ...rest }) => (
  <MuiAvatarView {...rest}>{children}</MuiAvatarView>
);

export default Avatar;
