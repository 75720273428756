import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import { useEffect } from "react";
import { ConfirmModal, ErrorModal } from "../../organisms";
import {
  useAuthStore,
  useModalStore,
  useReservationStore,
  useReserveCancelStore,
  useThingStore,
} from "../../../zustand";
import { useReserveThing } from "../../../pages/ReservationPage/hooks/useReserveThing";
import Format from "../../../services/FormatService";
import { useCancelReservation } from "../../../pages/ReservationPage/hooks/useCancelReservation";

export const HashModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, atk } = useAuthStore();

  const { headerTitle, mainContent, modalType, modalAction } = useModalStore();

  useEffect(() => {
    if (location.hash) {
      if (!headerTitle || !mainContent) {
        navigate(-1);
      }
    }
  }, [headerTitle, mainContent, modalType]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const reserveThing = useReserveThing();
  const { selectedDate, selectedTime } = useReservationStore();
  const { selectedThing } = useThingStore();
  const { reservedInfo, reservedThingId } = useReserveCancelStore();

  const cancelReservation = useCancelReservation();

  const handleModalConfirm = () => {
    if (modalType === "reserve") {
      // 예약하기
      if (selectedThing && selectedDate && selectedTime) {
        reserveThing({
          thingId: selectedThing?.thingId,
          reserveForm: {
            reserveDate: Format.dateISOFormat(selectedDate),
            startTime: selectedTime.startTime,
            endTime: selectedTime.endTime,
          },
        });
      }
    } else if (modalType === "cancel") {
      // 예약 취소
      if (reservedThingId && reservedInfo) {
        cancelReservation({
          thingId: reservedThingId,
          reserveForm: reservedInfo,
        });
        navigate(-1);
      }
    } else {
      modalAction?.();
    }
  };

  const handleModalClose = () => {
    // if (window.history.state) {
    if (!isLoggedIn && !atk) {
      navigate("/");
      return;
    }
    navigate(-1);
    // } else {
    //   navigate("/");
    // }
  };
  return (
    <div>
      {createPortal(
        location.hash === "#error" ? (
          <ErrorModal
            headerTitle={headerTitle ?? ""}
            mainContent={mainContent ?? [""]}
            handleModalClose={handleModalClose}
          />
        ) : location.hash === "#confirm" ? (
          <ConfirmModal
            headerTitle={headerTitle ?? ""}
            mainContent={mainContent ?? [""]}
            handleModalClose={handleModalClose}
            handleModalConfirm={handleModalConfirm}
          />
        ) : null,
        document.body
      )}
      <Outlet />
    </div>
  );
};
