import { useQuery } from "@tanstack/react-query";
import Api from "../../../services/ApiService";
import { queryKeys } from "../../../react-query/queryKeys";
import { GetTeamsResponse } from "./useTeams.type";
import { useIsAuthenticated } from "../../AuthRequiredApp/hooks/useIsAuthenticated";

const getTeams = async (center: string) => {
  const response = await Api.get<GetTeamsResponse>("/organization/teams", {
    center,
  });

  return response;
};

export const useTeams = (center: string) => {
  const isAuthenticated = useIsAuthenticated();
  const {
    data: response,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: [queryKeys.teams],
    queryFn: () => getTeams(center),
    enabled: isAuthenticated,
  });

  return { response, isSuccess, isError };
};
