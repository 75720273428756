import { FC } from "react";
import { styled, Box } from "@mui/material";
import { ReservationDateInfoProps } from "./ReservationDateInfo.type";
import { ReservationStatusLabel } from "../ReservationStatusLabel";

const DateInfoWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    fontSize: "14px",
    padding: "20px 12px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  [theme.breakpoints.up("tablet")]: {
    fontSize: "20px",
    padding: "30px 20px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  background: "#FFFFFF",
  borderRadius: "8px",
  textAlign: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
}));

const DateWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    fontSize: "14px",
    marginLeft: "10px",
  },
  [theme.breakpoints.up("tablet")]: {
    fontSize: "20px",
    marginLeft: "20px",
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "100%",
}));

export const ReservationDateInfo: FC<ReservationDateInfoProps> = ({
  reservationDate,
  reservationStartTime,
  reservationEndTime,
  reservationLabel,
}) => (
  <DateInfoWrapper>
    <ReservationStatusLabel status={reservationLabel} />
    <DateWrapper>
      {reservationDate.split("-")[0]}년 {reservationDate.split("-")[1]}월{" "}
      {reservationDate.split("-")[2]}일{" "}
      {reservationStartTime.slice(0, reservationStartTime.length - 3)} -{" "}
      {reservationEndTime.slice(0, reservationEndTime.length - 3)}
    </DateWrapper>
  </DateInfoWrapper>
);
