import { Typography, styled, Box } from "@mui/material";
import { FC } from "react";
import { ReservationDateInfo } from "../../molecules";
import {
  ReservationListProps,
  ReservationListTypographyProps,
} from "./ReservationList.type";

const ReservationListWrapper = styled(Box)({
  width: "100%",
  height: "100%",
});

const StyledTypography = styled(Typography)<ReservationListTypographyProps>(
  ({ theme, type }) => ({
    [theme.breakpoints.down("tablet")]: {
      fontSize: type === "title" ? "16px" : "14px",
    },
    fontSize: type === "title" ? "24px" : "20px",
  })
);

export const ReservationList: FC<ReservationListProps> = ({
  monthlyReservation,
}) => (
  <ReservationListWrapper>
    {monthlyReservation && monthlyReservation.length > 0 && (
      <StyledTypography type="description">
        *예약 내역은 당월만 볼 수 있어요.
      </StyledTypography>
    )}
    {monthlyReservation && monthlyReservation.length > 0 ? (
      monthlyReservation.map((reservation) => (
        <ReservationDateInfo
          {...reservation}
          key={
            reservation.thingId +
            reservation.reservationDate +
            reservation.reservationStartTime
          }
        />
      ))
    ) : (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingTop="20px"
      >
        <StyledTypography type="description">
          당월 예약 내역이 존재하지 않습니다.
        </StyledTypography>
      </Box>
    )}
  </ReservationListWrapper>
);
