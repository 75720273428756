import { Divider, styled, DialogTitle as MuiDialogTitle } from "@mui/material";
import { FC } from "react";
import { DialogTitleProps } from "./DialogTitle.type";

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const DialogTitle: FC<DialogTitleProps> = ({
  children,
  showDivider,
  ...rest
}) => (
  <MuiDialogTitle {...rest}>
    {children}
    {showDivider && <StyledDivider />}
  </MuiDialogTitle>
);

export default DialogTitle;
