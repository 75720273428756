import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  ModalAction,
  ModalState,
  ReserveSelectAction,
  ReserveSelectState,
  ThingSelectAction,
  ThingSelectState,
  AuthState,
  AuthAction,
  ReserveCancelState,
  ReserveCancelAction,
} from "./zustand.type";
import {
  ReservationInfo,
  ReserveFormType,
  Thing,
  TimeTableDetail,
} from "../model/ReservationType";

const initReservationState = {
  selectedDate: new Date(),
  selectedTime: { startTime: "", endTime: "" },
  isReserved: false,
  selectedInfo: undefined,
};

export const useAuthStore = create<AuthState & AuthAction>((set) => ({
  isLoggedIn: false,
  atk: null,
  setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn }),
  setAtk: (atk: string | null) => set({ atk }),
}));

export const useReservationStore = create<
  ReserveSelectState & ReserveSelectAction
>()(
  devtools((set) => ({
    ...initReservationState,
    setSelectedDate(date) {
      set(() => ({ selectedDate: date }));
    },
    setSelectedTime(time: TimeTableDetail) {
      set(() => ({ selectedTime: time }));
    },
    setIsReserved(status) {
      set(() => ({ isReserved: status }));
    },
    setSelectedInfo(info: ReservationInfo | undefined) {
      set(() => ({ selectedInfo: info }));
    },
    reset: () => {
      set(initReservationState);
    },
  }))
);

const initThingState = {
  selectedThing: undefined,
  thingImgUrl: "",
};
export const useThingStore = create<ThingSelectState & ThingSelectAction>()(
  devtools((set) => ({
    ...initThingState,
    setSelectedThing(thing: Thing | undefined) {
      set(() => ({ selectedThing: thing }));
    },
    setThingImgUrl(url: string) {
      set(() => ({ thingImgUrl: url }));
    },
    reset: () => {
      set(initThingState);
    },
  }))
);

const initModalState = {
  headerTitle: "",
  mainContent: [""],
  modalType: "",
};

export const useModalStore = create<ModalState & ModalAction>()(
  devtools((set) => ({
    ...initModalState,
    setHeaderTitle(title: string) {
      set(() => ({ headerTitle: title }));
    },
    setMainContent(contents?: string[]) {
      set(() => ({ mainContent: contents || [""] }));
    },
    setModalType(type: string) {
      set(() => ({ modalType: type }));
    },
    setModalAction(action: () => void) {
      set(() => ({ modalAction: action }));
    },
    setModal(modal: ModalState) {
      set(() => ({
        headerTitle: modal.headerTitle,
        mainContent: modal.mainContent,
        modalType: modal.modalType,
        modalAction: modal.modalAction,
      }));
    },

    reset: () => {
      set(initModalState);
    },
  }))
);

export const useReserveCancelStore = create<
  ReserveCancelState & ReserveCancelAction
>()(
  devtools((set) => ({
    reservedThingId: null,
    reservedInfo: null,
    setReservedThingId(thingId: number) {
      set(() => ({ reservedThingId: thingId }));
    },
    setReservedInfo(info: ReserveFormType | null) {
      set(() => ({ reservedInfo: info }));
    },
  }))
);
