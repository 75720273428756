import { useQuery } from "@tanstack/react-query";
import Api from "../../../services/ApiService";
import { UseMyPageResponseType } from "../../../model/MyPage.type";
import { queryKeys } from "../../../react-query/queryKeys";
import { useIsAuthenticated } from "../../AuthRequiredApp/hooks/useIsAuthenticated";

const retrieveMyPage = async () => {
  const response = await Api.get<UseMyPageResponseType>("/member/mypage");
  return response;
};

export const useMyPageReservationInfo = () => {
  const isAuthenticated = useIsAuthenticated();
  const {
    data: response,
    isSuccess,
    isError,
    error,
  } = useQuery({
    queryKey: [queryKeys.myPage],
    queryFn: () => retrieveMyPage(),
    enabled: isAuthenticated,
  });

  return { response, isError, error, isSuccess };
};
