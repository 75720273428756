import dayjs from "dayjs";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ReservationStatus } from "../../../model/ReservationType";
import Api from "../../../services/ApiService";
import { getDateDetail } from "./dateDetail";
import { queryKeys } from "../../../react-query";
import { useIsAuthenticated } from "../../AuthRequiredApp/hooks/useIsAuthenticated";
import { EBoolean } from "../../../common/commonContants";

 async function getReservationStatus(
  thingId: number,
  year: string,
  month: string,
  day: string,
  anonymous: EBoolean
): Promise<ReservationStatus> {
  const { data } = await Api.get<ReservationStatus>(
    `/reserve/${thingId}?year=${year}&month=${month}&day=${day}&anonymous=${anonymous}`
  );
  return data;
}

export function useReservationStatus(thingId: number): {
  reservations: ReservationStatus | undefined;
  updateDate: (newDate: dayjs.Dayjs) => void;
} {
  const isAuthenticated = useIsAuthenticated();
  const currentDate = getDateDetail(dayjs());

  const [date, setDate] = useState(currentDate);

  function updateDate(newDate: dayjs.Dayjs): void {
    setDate(getDateDetail(newDate));
  }

  const fallback = undefined;

  const { data: reservations = fallback } = useQuery<ReservationStatus>(
    [queryKeys.reservation, date.year, date.month, date.day],
    () =>
      getReservationStatus(
        thingId,
        date.year,
        date.month,
        date.day,
        EBoolean.TRUE
      ),
    {
      enabled: isAuthenticated,
    }
  );

  return { reservations, updateDate };
}
