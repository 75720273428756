export enum queryKeys {
  login = "login",
  menu = "menu",
  userInfo = "userInfo",
  myPage = "myPage",
  teams = "teams",
  reservation = "reservation",
  currentUseStatus = "currentUseStatus",
  vacancyAlarm = "vacancyAlarm",
}
