import { FC } from "react";
import { MainPageProps } from "./MainPage.type";
import { MainPageTemplate } from "../../components";
import { useMenu } from "./hooks/useMenu";
import { useCanReserveThing } from "./hooks/useCanReserveThing";

export const MainPage: FC<MainPageProps> = () => {
  const menuList = useMenu();
  const reserveStatus = useCanReserveThing();

  let currentUseStatusText;
  if (reserveStatus.isReserveAvailTime === true) {
    if (reserveStatus.isFreeToReserve) {
      currentUseStatusText = "현재 시간대의 안마의자 예약이 가능합니다!";
    } else {
      currentUseStatusText = "현재 시간대의 안마의자 예약이 불가능합니다!";
    }
  } else if (reserveStatus.isReserveAvailTime === false) {
    currentUseStatusText =
      "지금은 예약 없이 자율적으로 이용 가능한 시간입니다.";
  }

  return (
    <MainPageTemplate
      menuList={menuList}
      currentUseStatusText={currentUseStatusText || ""}
      canUse={
        reserveStatus.isReserveAvailTime === true
          ? reserveStatus.isFreeToReserve
          : true
      }
    />
  );
};
