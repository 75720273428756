import { useNavigate } from "react-router-dom";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";

import Api from "../../../services/ApiService";
import { ReserveFormType } from "../../../model/ReservationType";

async function reserveThing(
  thingId: number | undefined,
  reserveForm: ReserveFormType
): Promise<ReserveFormType | null> {
  if (thingId) {
    const { data } = await Api.post<null, ReserveFormType>(
      `/reserve/${thingId}`,
      reserveForm
    );
    return data;
  }
  return null;
}

export interface ReserveMutationProps {
  thingId: number | undefined;
  reserveForm: ReserveFormType;
}

export function useReserveThing(): UseMutateFunction<
  ReserveFormType | null,
  unknown,
  ReserveMutationProps,
  unknown
> {
  const navigate = useNavigate();
  const { mutate } = useMutation(
    ({ thingId, reserveForm }: ReserveMutationProps) =>
      reserveThing(thingId, reserveForm),
    {
      onSuccess: (data: ReserveFormType | null) => {
        navigate(
          `/reservation/complete?nickName=${data?.nickName}&reserveDate=${data?.reserveDate}&startTime=${data?.startTime}&endTime=${data?.endTime}`
        );
      },
    }
  );

  return mutate;
}
