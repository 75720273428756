import { DialogContent, DialogTitle, styled } from "@mui/material";
import { FC, useState } from "react";
import { Dialog, DialogActions } from "../../atoms";

import { ModalProps } from "./Modal.type";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    padding: "8px",
  },
  "& .MuiDialogTitle-root": {
    "&>p": {
      fontSize: "18px",
    },
  },
  "& .MuiDialogContent-root": {
    "& p": {
      fontSize: "14px",
      lineHeight: "25px",
    },
  },
  "& .MuiDialogActions-root": {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
  },
}));

export const Modal: FC<ModalProps> = ({
  header,
  main,
  footer,
  open,
  fullWidth,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleClose = (
    event: object,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setIsOpen(false);
    }
  };

  return (
    <StyledDialog
      fullWidth={fullWidth}
      open={isOpen}
      onClose={handleClose}
      {...rest}
    >
      <DialogTitle {...header} />
      <DialogContent {...main} />
      <DialogActions {...footer} />
    </StyledDialog>
  );
};
