import { styled, Box, Theme, useMediaQuery, Typography } from "@mui/material";
import { VacancyAlarmForm, VacancyAlarmList } from "../../organisms";
import { a11yProps, Tab, Tabs, TabPanel, Image } from "../../atoms";
import slackIcon from "../../../assets/img/ico_slack.svg";
import { useMenu } from "../../../pages/MainPage/hooks/useMenu";

const VacancyAlarmWrapper = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("tablet")]: {
    height: "calc(100vh - 58px)",
    padding: "15px",
    paddingTop: "58px",
  },
  [theme.breakpoints.up("tablet")]: {
    padding: "100px",
    paddingTop: "120px",
    height: "calc(100vh - 120px)",
  },
}));

const VacancyAlarmItem1 = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    flex: "3",
    marginBottom: "20px",
  },
  [theme.breakpoints.up("tablet")]: {
    flex: "1",
  },
}));

const VacancyAlarmItem2 = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    flex: "4",
  },
  [theme.breakpoints.up("tablet")]: {
    flex: "2",
  },
}));

const InfoMessage = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    fontSize: "16px",
    paddingBottom: "20px",
    paddingTop: "20px",
    gap: "8px",
  },
  [theme.breakpoints.up("tablet")]: {
    fontSize: "20px",
    gap: "10px",
    paddingBottom: "40px",
    paddingTop: "20px",
  },
}));

const TabArea = styled(Box)({
  flex: "1",
});

const StyledTab = styled(Tab)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    minHeight: "20px",
    padding: "0px 10px 10px",
    width: "fit-content",
  },
  [theme.breakpoints.up("tablet")]: { width: "fit-content", fontSize: "20px" },
}));

const StyledTabPanel = styled(TabPanel)({
  height: "calc(100% - 10px)",
  flex: "none",
  display: "flex",
  flexDirection: "column",
});

const StyledTabs = styled(Tabs)({
  ".MuiTabs-flexContainer": {
    borderBottom: "0px",
  },
});

const VacancyAlarm = ({
  thingId,
  thingImageUrl,
}: {
  thingId: number;
  thingImageUrl?: string;
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("tablet")
  );

  const renderVacancyAlarmList = () => (
    <>
      <Typography
        fontSize={isMobile ? 12 : 20}
        marginBottom={isMobile ? 0 : "30px"}
      >
        최대 3개까지 등록할 수 있어요
      </Typography>
      <VacancyAlarmList imageUrl={thingImageUrl} isDelete />
    </>
  );

  if (isMobile) {
    return (
      <>
        <VacancyAlarmItem1>{renderVacancyAlarmList()}</VacancyAlarmItem1>
        <VacancyAlarmItem2>
          <VacancyAlarmForm thingId={thingId} />
        </VacancyAlarmItem2>
      </>
    );
  }
  return (
    <>
      <VacancyAlarmItem1>
        <VacancyAlarmForm thingId={thingId} />
      </VacancyAlarmItem1>
      <VacancyAlarmItem2>{renderVacancyAlarmList()}</VacancyAlarmItem2>
    </>
  );
};

export const VacancyAlarmMain = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("tablet")
  );

  const thingList = useMenu();

  return (
    <VacancyAlarmWrapper>
      <InfoMessage>
        <Box>원하는 시간에 예약이 가능하면</Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <Image
            src={slackIcon}
            title="slack"
            width={isMobile ? "14px" : "20px"}
            height={isMobile ? "14px" : "20px"}
          />
          Slack으로 알려드려요!
        </Box>
      </InfoMessage>
      <TabArea>
        <StyledTabs value={0} ara-aria-label="activities tabs">
          {thingList.map((thing) => (
            <StyledTab label={thing.name} {...a11yProps(0)} />
          ))}
        </StyledTabs>
        {thingList.map((thing) => (
          <StyledTabPanel value={0} index={0}>
            <VacancyAlarm
              thingId={thing.thingId}
              thingImageUrl={thing.imageUrl}
            />
          </StyledTabPanel>
        ))}
      </TabArea>
    </VacancyAlarmWrapper>
  );
};
