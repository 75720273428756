import { Button, styled } from "@mui/material";
import { FC } from "react";
import { IconButtonProps } from "./IconButton.type";
import { Image } from "../../atoms";

const StyledIconButton = styled(Button)(() => ({
  minWidth: 0,
  padding: 0,
}));

export const IconButton: FC<IconButtonProps> = ({
  onClick,
  icoUrl,
  icoAlt,
  width,
  height,
}) => (
  <StyledIconButton onClick={onClick} disableRipple>
    <Image src={icoUrl} alt={icoAlt} width={width} height={height} />
  </StyledIconButton>
);

export default IconButton;
