import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import customTheme from "./theme";
import {
  ReservationCompleteMain,
  HashModal,
  AlwaysHeaderRequiredTemplate,
} from "./components";
import {
  MainPage,
  MyPage,
  SlackLoginHandlerPage,
  AdditionalInfoMainPage,
  ReservationPage,
  AuthRequiredApp,
  FallbackComponent,
  ErrorHandlingComponent,
} from "./pages";
import { VacancyAlarmPage } from "./pages/VacancyAlarmPage/VacancyAlarm";

const LoggedInApp = () => (
  <Routes>
    <Route element={<HashModal />}>
      <Route element={<AlwaysHeaderRequiredTemplate />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/signup" element={<AdditionalInfoMainPage />} />
        <Route path="/mypage" element={<MyPage />} />
        <Route path="/reservation" element={<ReservationPage />} />
        <Route path="/vacancy-alarm" element={<VacancyAlarmPage />} />
        <Route
          path="/reservation/complete"
          element={<ReservationCompleteMain />}
        />
      </Route>
      <Route
        path="/*"
        element={
          <ErrorHandlingComponent
            message={["404", "존재하지 않는 페이지입니다."]}
            chipButton={{ label: "메인으로" }}
          />
        }
      />
    </Route>
  </Routes>
);

const App = () => (
  <div className="App">
    <ThemeProvider theme={customTheme}>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <AuthRequiredApp
          loggedInApp={<LoggedInApp />}
          oidcLoginHandlerPath="/auth/slack"
          oidcLoginHandlerComponent={<SlackLoginHandlerPage />}
        />
      </ErrorBoundary>
    </ThemeProvider>
  </div>
);
export default App;
