import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthRequiredAppProps } from "./AuthRequiredApp.type";
import { useReissueToken } from "./hooks";
import { useAuthStore } from "../../zustand";

export const AuthRequiredApp = ({
  loggedInApp,
  oidcLoginHandlerPath,
  oidcLoginHandlerComponent,
}: PropsWithChildren<AuthRequiredAppProps>): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const setIsLoggedIn = useAuthStore((state) => state.setIsLoggedIn);
  const setAtk = useAuthStore((state) => state.setAtk);

  const {
    isError,
    isSuccess,
    response,
    mutate: getSession,
  } = useReissueToken();

  useEffect(() => {
    if (location.pathname !== oidcLoginHandlerPath) {
      getSession();
    }
  }, []);

  useEffect(() => {
    if (isSuccess && response?.data) {
      setIsLoggedIn(true);
      setAtk(response.data.accessToken);
      navigate(`${window.location.pathname}${window.location.search}`);
    }
  }, [isSuccess]);

  if (location.pathname === oidcLoginHandlerPath) {
    return oidcLoginHandlerComponent;
  }

  if (isLoggedIn) {
    return loggedInApp;
  }

  if (!isError && !isSuccess) {
    return <div />;
  }

  return <div />;
};
