import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { ThingReserveStatus } from "../../../model/ReservationType";
import { SECOND, queryKeys } from "../../../react-query";
import Api from "../../../services/ApiService";
import { useIsAuthenticated } from "../../AuthRequiredApp/hooks/useIsAuthenticated";

// 현재 안마의자만 서비스 중이므로 사용상태 조회 API 호출에 필요한 thing id는 안마의자의 thing id로 하드코딩함
const MASSAGE_CHAIR_THING_ID = 1;

async function getThingReserveStatus(
  thingId: number
): Promise<ThingReserveStatus> {
  const { data } = await Api.get<ThingReserveStatus>(
    `/thing/${thingId}/status`
  );

  return data;
}

export const useCanReserveThing = () => {
  const isAuthenticated = useIsAuthenticated();

  const { data, isFetching } = useQuery<ThingReserveStatus>(
    [queryKeys.currentUseStatus],
    () => getThingReserveStatus(MASSAGE_CHAIR_THING_ID),
    {
      enabled: isAuthenticated,
      refetchInterval: 10 * SECOND,
      refetchIntervalInBackground: false,
    }
  );

  const isFreeToReserve = useMemo(() => {
    const currMinute = new Date().getMinutes();

    return (
      ((currMinute >= 0 && currMinute <= 20) ||
        (currMinute >= 30 && currMinute <= 50)) &&
      data?.isFree !== undefined &&
      data.isFree
    );
  }, [isFetching]);

  return { isFreeToReserve, isReserveAvailTime: data?.isReserveAvailTime };
  // isFree 현재 예약대상이 사용중이지 않고, 현시간 예약슬롯 기준 종료 10분전 이전 --> "예약"이 가능한지 체크
  // isReserveAvailTime 예약대상의 정책상 예약가능한 시간대인지 의미
};
