import { FC } from "react";

import {
  styled,
  List,
  ListItem,
  Divider,
  Box,
  Typography,
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { ReservationContentProps } from "./ReservationContent.type";
import { MobileTimeTable } from "../../molecules";
import { Button } from "../../atoms";
import { useReservationStore } from "../../../zustand";
import { ReservationProfile } from "../../molecules/ReservationProfile";

const ReservationContentWrapper = styled(List)(({ theme }) => ({
  position: "absolute",
  bottom: "0px",
  height: "200px",
  width: "100%",
  borderRadius: "50px",
  boxShadow: `0px -4px 4px ${theme.palette.grey[200]}`,
  background: "#ffffff",
}));

const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "10px",
});

export const MobileReservationContent: FC<ReservationContentProps> = ({
  timeTable,
  handleReservationBtnClick,
  isBtnDisabled,
  checkIsReserved,
}) => {
  const { selectedTime, isReserved, selectedInfo } = useReservationStore();

  return (
    <ReservationContentWrapper>
      <ListItem>
        {timeTable && (
          <MobileTimeTable
            timeTable={timeTable}
            isBtnDisabled={isBtnDisabled}
            checkReserveState={checkIsReserved}
          />
        )}
      </ListItem>
      <Divider textAlign="left" sx={{ marginTop: "20px" }}>
        예약 현황
      </Divider>
      <ListItem>
        <ButtonWrapper>
          {selectedTime.startTime === "" && (
            <Typography>예약 시간을 선택해주세요.</Typography>
          )}
          {isReserved && selectedInfo && (
            <ReservationProfile
              name={selectedInfo?.nickname}
              imgUrl={selectedInfo?.profileImageUrl}
              size="s"
            />
          )}
          {selectedTime.startTime !== "" && !isReserved && (
            <Button
              variant="contained"
              btnsize={54}
              color="secondaryBlue"
              onClick={handleReservationBtnClick}
            >
              예약하기
            </Button>
          )}
        </ButtonWrapper>
      </ListItem>
    </ReservationContentWrapper>
  );
};
