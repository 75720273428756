import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Box, useMediaQuery, Theme } from "@mui/material";
import { MobileHeaderGNB } from "./MobileHeaderGNB.component";
import { WebHeaderGNB } from "./WebHeaderGNB.component";
import { HeaderGNBType } from "./HeaderGNB.type";
import { useLogout } from "../../../pages/MainPage/hooks/useLogout.hook";
import { useModalStore } from "../../../zustand";
import { useMenu } from "../../../pages/MainPage/hooks/useMenu";
import { MenuType } from "../../../model/MenuType";
import { useUserInfo } from "../../../pages";
import { useReservationMenu } from "../../../pages/ReservationPage/hooks/useReservationThing";

export const HeaderGNB: FC<HeaderGNBType> = ({ isLoginPage }) => {
  const mobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("laptop")
  );

  const qs = queryString.parse(window.location.search);
  const { thingId } = qs;
  const { response: menu } = useReservationMenu(Number(thingId), false);

  const location = useLocation();
  const navigate = useNavigate();
  const { mutate: logout } = useLogout();
  const { response: userInfoResponse } = useUserInfo();
  const useMenuResponse = useMenu();
  const { setHeaderTitle, setMainContent, setModalType } = useModalStore();
  const [inSignUpPage, setInSignUpPage] = useState(false);

  const setLoginRequireModal = () => {
    setHeaderTitle("로그인이 필요한 기능입니다.");
    setMainContent(["로그인 해주시길 바랍니다."]);
    setModalType("cancel");

    window.location.assign("#error");
  };

  const setAdditionalInfoRequireModal = () => {
    setHeaderTitle("추가정보를 기입해주세요.");
    setMainContent(["소속 팀 정보 기입 후 이용이 가능한 메뉴입니다."]);
    setModalType("cancel");

    window.location.assign("#error");
  };

  const handleMenuItemButtonClick = (menu: MenuType) => {
    if (inSignUpPage) {
      setAdditionalInfoRequireModal();
    } else {
      navigate(`/reservation?thingId=${menu.thingId}`);
    }
  };

  const handleWebReserveMenuButtonClick = () => {
    if (isLoginPage) {
      setLoginRequireModal();
    }
  };

  const handleMobileReserveMenuButtonClick = () => {
    if (inSignUpPage) {
      setAdditionalInfoRequireModal();
    } else {
      navigate("/");
    }
  };

  const titleWrapperClickHanlder = () => {
    if (!isLoginPage) {
      if (userInfoResponse?.team === null) {
        setAdditionalInfoRequireModal();
      } else {
        navigate("/");
      }
    } else {
      setLoginRequireModal();
    }
  };

  // url에 따른 제목 render - refactoring 필요
  const renderPathname = (): string => {
    if (location.pathname === "/") return "Build X Launch Reserve";
    if (location.pathname === "/reservation/complete") return "예약완료";
    if (location.pathname === "/reservation") {
      return menu?.name ?? "";
    }
    if (location.pathname === "/mypage") return "마이페이지";
    if (location.pathname === "/vacancy-alarm") return "빈자리 알림 등록";
    return "Build X Launch Reserve";
  };

  useEffect(() => {
    setInSignUpPage(location.pathname === "/signup");
  }, [location.pathname]);

  useEffect(() => {
    if (userInfoResponse) {
      if (userInfoResponse.team === null && location.pathname !== "/signup") {
        navigate("/signup", { replace: true });
      }
    }
  }, [userInfoResponse]);

  const myPageOnClickHandler = () => {
    if (!isLoginPage) {
      if (inSignUpPage) {
        setAdditionalInfoRequireModal();
      } else {
        navigate("/mypage");
      }
    } else {
      setLoginRequireModal();
    }
  };

  const vacancyAlarmOnClickHandler = () => {
    if (!isLoginPage) {
      if (inSignUpPage) {
        setAdditionalInfoRequireModal();
      } else {
        navigate("/vacancy-alarm");
      }
    } else {
      setLoginRequireModal();
    }
  };
  return (
    <Box>
      {mobileScreen ? (
        <MobileHeaderGNB
          title={renderPathname()}
          reserveButtonClickHandler={handleMobileReserveMenuButtonClick}
          logoutHandler={logout}
          isLoggedIn={!isLoginPage}
          myPageOnClickHandler={myPageOnClickHandler}
          userInfo={userInfoResponse}
          menuList={useMenuResponse}
          menuItemClickHandler={handleMenuItemButtonClick}
          vacancyAlarmOnClickHandler={vacancyAlarmOnClickHandler}
        />
      ) : (
        <WebHeaderGNB
          titleWrapperClickHanlder={titleWrapperClickHanlder}
          logoutHandler={logout}
          isLoggedIn={!isLoginPage}
          myPageOnClickHandler={myPageOnClickHandler}
          menuList={useMenuResponse}
          menuItemClickHandler={handleMenuItemButtonClick}
          reserveButtonClickHandler={handleWebReserveMenuButtonClick}
          vacancyAlarmOnClickHandler={vacancyAlarmOnClickHandler}
        />
      )}
    </Box>
  );
};
