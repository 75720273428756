export const CLIENT_DEVICE_ID_KEY = "blreserve_cid";

export enum EBoolean {
  TRUE = "true",
  FASLE = "false",
}

export enum EReservationStatus {
  INPROGRESS = "INPROGRESS",
  SCHEDULED = "SCHEDULED",
  COMPLETED = "COMPLETED",
}

export enum EAvailDay {
  ALL = "전일",
  WEEKDAYS = "평일",
  WEEKEND = "주말",
}

export const CenterId = {
  buildCenterId: 1,
  launchCenterId: 2,
};

interface WeekdaysType {
  MONDAY: string;
  TUESDAY: string;
  WEDNESDAY: string;
  THURSDAY: string;
  FRIDAY: string;
  [key: string]: string;
}

interface WeekendsType {
  SATURDAY: string;
  SUNDAY: string;
  [key: string]: string;
}

export const Weekdays: WeekdaysType = {
  MONDAY: "월",
  TUESDAY: "화",
  WEDNESDAY: "수",
  THURSDAY: "목",
  FRIDAY: "금",
};

export const Weekends: WeekendsType = {
  SATURDAY: "토",
  SUNDAY: "일",
};

export const Days: WeekdaysType & WeekdaysType = { ...Weekdays, ...Weekends };
