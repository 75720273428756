import dayjs from "dayjs";

export interface DateDetail {
  month: string;
  year: string;
  day: string;
}

export function getDateDetail(initialDate: dayjs.Dayjs): DateDetail {
  const day = initialDate.format("DD");
  const month = initialDate.format("MM");
  const year = initialDate.format("YYYY");
  return { day, month, year };
}
