import { AxiosError } from "axios";
import { ErrorHandlingPage } from "./ErrorHandlingPage";

export const FallbackComponent = ({
  error,
  resetErrorBoundary,
}: {
  error: Error | AxiosError | null;
  resetErrorBoundary: () => void;
}) => (
  <ErrorHandlingPage error={error} resetErrorBoundary={resetErrorBoundary} />
);
