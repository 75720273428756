import { FC } from "react";
import { AxiosError, isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { ErrorHandlingPageType } from "./ErrorHandlingPage.type";
import {
  AxiosErrorHandlingComponent,
  ErrorHandlingComponent,
  SilentLoginErrorHandlingComponent,
} from "./ErrorHandlingComponents";
import { CLIENT_DEVICE_ID_KEY } from "../../common/commonContants";

export const ErrorHandlingPage: FC<ErrorHandlingPageType> = ({
  error,
  resetErrorBoundary,
}) => {
  const navigate = useNavigate();

  // 로그아웃의 경우 네트워크 에러 무시 그냥 무조건 로그아웃
  if ((error as AxiosError).config?.url?.includes("logout")) {
    localStorage.removeItem(CLIENT_DEVICE_ID_KEY);
    window.location.href = "/";
  }

  // 네트워크 & 서버 관련 에러
  if (
    (error as AxiosError).code === AxiosError.ECONNABORTED ||
    (error as AxiosError).code === AxiosError.ERR_NETWORK ||
    (error as AxiosError).code === AxiosError.ETIMEDOUT
  ) {
    const message = [
      "서버가 응답하지 않습니다.",
      "잠시 후 다시 방문해 주세요.",
    ];
    return <ErrorHandlingComponent message={message} />;
  }

  // silent 로그인 관련
  if ((error as AxiosError).config?.url?.includes("/auth/reissue")) {
    return <SilentLoginErrorHandlingComponent error={error as AxiosError} />;
  }

  // 슬랙 로그인 관련
  if ((error as AxiosError).config?.url?.includes("/auth/login")) {
    return (
      <ErrorHandlingComponent
        message={[
          "슬랙으로 로그인 하던 중에 에러가 발생했습니다.",
          "메인으로 돌아갑니다.",
        ]}
        chipButton={{
          label: "메인으로",
          onClick: () => {
            window.location.href = "/";
          },
        }}
      />
    );
  }

  // 최초 가입 페이지 관련
  if ((error as AxiosError).config?.url?.includes("/auth/enroll/team")) {
    return (
      <ErrorHandlingComponent
        message={[
          "팀 정보 저장 중 에러가 발생했습니다.",
          "메인으로 돌아갑니다.",
        ]}
        chipButton={{
          label: "메인으로",
          onClick: () => {
            window.location.href = "/";
          },
        }}
      />
    );
  }

  // 그 외 API 호출간 발생한 에러 처리
  if (isAxiosError(error)) {
    return (
      <AxiosErrorHandlingComponent
        error={error}
        resetErrorBoundary={resetErrorBoundary}
      />
    );
  }

  // 그외에 에러들
  return (
    <ErrorHandlingComponent
      message={["에러가 발생했습니다.", "잠시 후 다시 시도해주세요."]}
      chipButton={{
        label: "메인으로",
        onClick: () => {
          resetErrorBoundary();
          navigate("/");
        },
      }}
    />
  );
};
