import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Modal } from "../../molecules";
import { Button } from "../../atoms";
import { ErrorModalType } from "./ErrorModal.type";

export const ErrorModal: FC<ErrorModalType> = ({
  headerTitle,
  mainContent,
  handleModalClose,
}) => (
  <Modal
    open
    header={{ children: <Typography>{headerTitle}</Typography> }}
    main={{
      children: (
        <Box>
          {mainContent.map((content) => (
            <Typography key={content}>{content}</Typography>
          ))}
        </Box>
      ),
    }}
    footer={{
      children: (
        <Box>
          <Button
            variant="contained"
            color="primaryPink"
            btnsize={40}
            onClick={handleModalClose}
          >
            확인
          </Button>
        </Box>
      ),
    }}
  />
);
