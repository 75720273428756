import { FC } from "react";
import { Grid, styled, Typography } from "@mui/material";
import {
  ReservationCardProps,
  ReservationCardTypographyProps,
} from "./ReservationCard.type";
import { ChipButton, Image } from "../../atoms";
import { useModalStore, useReserveCancelStore } from "../../../zustand";

const FONT_S = "10px";
const FONT_M = "14px";
const FONT_L = "18px";

const ReservationCardWrapper = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  width: "fit-content",
  flexWrap: "nowrap",
  backgroundColor: theme.palette.grey[100],
  borderRadius: "8px",
}));

const StyledChipButton = styled(ChipButton)(({ theme }) => ({
  [theme.breakpoints.down("laptop")]: {
    margin: theme.spacing(3, 3),
    [theme.breakpoints.down("tablet")]: {
      margin: theme.spacing(2, 2),
      width: "95px",
    },
  },
  [theme.breakpoints.up("laptop")]: {
    margin: theme.spacing(2, 2),
    width: "95px",
    height: "30px",
    fontSize: "14px",
  },
  borderRadius: "100px",
  cursor: "pointer",
}));

const ImageWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("laptop")]: {
    margin: theme.spacing(4, 3, 4, 3),
    height: "40px",
    width: "40px",
    [theme.breakpoints.down("tablet")]: {
      margin: theme.spacing(3, 2, 3, 2),
      height: "30px",
      width: "30px",
    },
  },
  [theme.breakpoints.up("laptop")]: {
    margin: theme.spacing(4, 3, 4, 3),
    height: "30px",
    width: "30px",
  },
}));

const ReservationDateWrapper = styled(Grid)({
  width: "auto",
});

const StyledTypography = styled(Typography)<ReservationCardTypographyProps>(
  ({ theme, type }) => ({
    [theme.breakpoints.down("laptop")]: {
      marginBottom: theme.spacing(0.5),
      fontSize: type === "date" ? FONT_L : FONT_M,
      [theme.breakpoints.down("tablet")]: {
        fontSize: type === "date" ? FONT_M : FONT_S,
      },
    },
    [theme.breakpoints.up("laptop")]: {
      fontSize: type === "date" ? FONT_L : FONT_M,
    },
  })
);

export const ReservationCard: FC<ReservationCardProps> = ({
  thingId,
  reservationDate,
  reservationStartTime,
  reservationEndTime,
  thingImageUrl,
}) => {
  const { setHeaderTitle, setMainContent, setModalType } = useModalStore();
  const { setReservedThingId, setReservedInfo } = useReserveCancelStore();

  const handleClickCancel = () => {
    // 예약 취소 데이터 세팅
    setReservedThingId(Number(thingId));
    setReservedInfo({
      reserveDate: reservationDate,
      startTime: reservationStartTime,
      endTime: reservationEndTime,
    });
    setHeaderTitle("예약취소 하시겠습니까?");
    setMainContent([
      `일자 : ${reservationDate}`,
      `시간 : ${reservationStartTime.slice(0, 5)} - ${reservationEndTime.slice(
        0,
        5
      )}`,
    ]);
    setModalType("cancel");

    window.location.assign("#confirm");
  };

  return (
    <ReservationCardWrapper container direction="row">
      <ImageWrapper>
        <Image src={thingImageUrl} />
      </ImageWrapper>
      <ReservationDateWrapper container direction="column">
        <StyledTypography noWrap type="date">
          {reservationDate.split("-")[0]}년 {reservationDate.split("-")[1]}월{" "}
          {reservationDate.split("-")[2]}일
        </StyledTypography>
        <StyledTypography noWrap type="time">
          {reservationStartTime.slice(0, reservationStartTime.length - 3)} -{" "}
          {reservationEndTime.slice(0, reservationEndTime.length - 3)}
        </StyledTypography>
      </ReservationDateWrapper>
      <StyledChipButton
        label="취소하기"
        className="cancel"
        onClick={handleClickCancel}
      />
    </ReservationCardWrapper>
  );
};
