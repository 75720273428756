import { FC } from "react";
import {
  TableCell,
  TableRow,
  Box,
  styled,
  Typography,
  Grid,
  useMediaQuery,
  Theme,
  MenuItem,
  Table,
  TableBody,
} from "@mui/material";
import { AdditionalInfoMainProps } from "./AdditionalInfoMain.type";
import { Image, Button, Select } from "../../atoms";
import { useTeams } from "../../../pages";
import { BUILD_CENTER, LAUNCH_CENTER } from "../../../react-query";
import { CenterId } from "../../../common/commonContants";

const AdditionalInfoWrapper = styled(Grid)({
  justifyContent: "center",
  alignItems: "center",
  minHeight: "calc(100vh + 70px)",
  width: "100%",
});

const Topic = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    margin: theme.spacing(5, "auto"),
    fontSize: "35px",
    fontWeight: 700,
  },
  margin: theme.spacing(4, "auto"),
  fontSize: "24px",
  fontWeight: 700,
}));

const StyledTableCellKey = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    fontSize: "25px",
  },
  fontSize: "17px",
  border: 0,
  width: "45%",
}));

const StyledTableCellValue = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    fontSize: "25px",
  },
  fontSize: "17px",
  border: 0,
  width: "55%",
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    marginTop: "96px",
  },
  justifyContent: "center",
  alignItems: "center",
  marginTop: "53px",
}));

export const AdditionalInfoMain: FC<AdditionalInfoMainProps> = ({
  userInfo,
  selectedTeam,
  setSelectedTeam,
  completeRegister,
}) => {
  const { response: teamsResponse } = useTeams(
    userInfo.center?.centerId === CenterId.buildCenterId
      ? BUILD_CENTER
      : LAUNCH_CENTER
  );
  const mobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("laptop")
  );
  return (
    <AdditionalInfoWrapper container direction="column" rowSpacing={2}>
      <Image
        src="/assets/img/img_slack.svg"
        alt="slack img"
        width={mobileScreen ? "auto" : "100"}
      />
      <Topic>추가 정보 기입</Topic>

      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCellKey align="right" variant="head">
              센터 :
            </StyledTableCellKey>
            <StyledTableCellValue align="left">
              {userInfo.center?.centerId === CenterId.buildCenterId
                ? "빌드센터"
                : "론치센터"}
            </StyledTableCellValue>
          </TableRow>
          <TableRow>
            <StyledTableCellKey align="right" variant="head">
              닉네임 :
            </StyledTableCellKey>
            <StyledTableCellValue align="left">
              {userInfo.nickName}
            </StyledTableCellValue>
          </TableRow>
          <TableRow>
            <StyledTableCellKey align="right" variant="head">
              팀명 :
            </StyledTableCellKey>
            <StyledTableCellValue align="left">
              <Select
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value as string)}
                displayEmpty
                renderValue={
                  selectedTeam === ""
                    ? () => (
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          소속된 팀을 선택해주세요.
                        </div>
                      )
                    : undefined
                }
              >
                {teamsResponse?.data &&
                  teamsResponse.data.map((team) => (
                    <MenuItem key={team.name} value={team.name}>
                      {team.name}
                    </MenuItem>
                  ))}
              </Select>
            </StyledTableCellValue>
          </TableRow>
        </TableBody>
      </Table>
      <ButtonWrapper>
        <Button
          variant="contained"
          color="secondaryBlue"
          btnsize={mobileScreen ? 40 : 60}
          onClick={() => completeRegister()}
        >
          가입 완료
        </Button>
      </ButtonWrapper>
    </AdditionalInfoWrapper>
  );
};
