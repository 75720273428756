import { reissueToken } from "../pages";
import { CommonResponse, ReissueAtkResponseType } from "./services.types";

let refreshPromise: Promise<CommonResponse<ReissueAtkResponseType>> | null;

export const refreshToken = () => {
  if (!refreshPromise) {
    refreshPromise = reissueToken().then((res) => {
      refreshPromise = null;
      return res;
    });
  }

  return refreshPromise;
};
