import {
  ChipProps as MuiChipProps,
  Chip as MuiChip,
  styled,
} from "@mui/material";
import { RoundChipProps } from ".";

const StyledChipButton = styled(MuiChip)<MuiChipProps>(({ theme }) => ({
  "&.MuiChip-root": {
    "&.check": {
      backgroundColor: theme.palette.selectGreen.main,
    },
    "&.cancel": {
      backgroundColor: theme.palette.cancelRed.main,
      color: theme.palette.white.main,
    },
    ".MuiChip-label": {
      padding: 0,
    },
    borderRadius: "100%",
  },
}));

export const RoundChip = ({ size, sx, ...rest }: RoundChipProps) => {
  const widthBySize = size === "l" ? 48 : size === "m" ? 35 : 28;
  const fontBySize = size === "l" ? 20 : size === "m" ? 16 : 12;

  return (
    <StyledChipButton
      sx={{
        width: `${widthBySize}px`,
        height: `${widthBySize}px`,
        fontSize: `${fontBySize}px`,
        ...sx,
      }}
      {...rest}
    />
  );
};
