import { FC, useState } from "react";
import { styled, Typography, Box } from "@mui/material";
import { WebHeaderGNBType } from "./WebHeaderGNB.type";
import { IconButton } from "../../atoms";
import exitIcon from "../../../assets/img/ico_exit.svg";

const HeaderWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  position: "absolute",
  backgroundColor: "#ffffff",
}));

const TitleWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  paddingLeft: "60px",
  justifyContent: "center",
  height: "80px",
}));

const MenuWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  paddingLeft: theme.spacing(15),
}));

const Menu = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const MenuTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "80px",
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  cursor: "pointer",
}));

const SubMenu = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const SubMenuTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "30px",
  marginBottom: theme.spacing(1),
  cursor: "pointer",
}));

const LogoutWrapper = styled(Box)(() => ({
  display: "flex",
  cursor: "pointer",
  paddingLeft: "60px",
  justifyContent: "center",
  height: "80px",
  alignItems: "center",
  position: "absolute",
  right: "60px",
}));

export const WebHeaderGNB: FC<WebHeaderGNBType> = ({
  titleWrapperClickHanlder,
  logoutHandler,
  isLoggedIn,
  myPageOnClickHandler,
  menuList,
  menuItemClickHandler,
  reserveButtonClickHandler,
  vacancyAlarmOnClickHandler,
}) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <HeaderWrapper>
      <TitleWrapper onClick={titleWrapperClickHanlder}>
        <Typography fontSize={20} fontWeight={600}>
          Build X Launch Reserve
        </Typography>
        <Typography fontSize={14}>
          LG CNS 빌드/론치센터 통합예약시스템
        </Typography>
      </TitleWrapper>
      <MenuWrapper>
        <Menu>
          <MenuTitle
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={reserveButtonClickHandler}
          >
            <Typography fontSize={18}>예약서비스</Typography>
          </MenuTitle>
          {hover && (
            <SubMenu
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {menuList &&
                menuList.map((menuItem) => (
                  <SubMenuTitle
                    key={menuItem.thingId}
                    onClick={() => menuItemClickHandler(menuItem)}
                  >
                    {menuItem.name}
                  </SubMenuTitle>
                ))}
            </SubMenu>
          )}
        </Menu>
        <MenuTitle onClick={vacancyAlarmOnClickHandler}>
          <Typography fontSize={18}>빈자리 알림 등록</Typography>
        </MenuTitle>
        <MenuTitle onClick={myPageOnClickHandler}>
          <Typography fontSize={18}>마이페이지</Typography>
        </MenuTitle>
      </MenuWrapper>
      <LogoutWrapper onClick={logoutHandler}>
        {isLoggedIn && (
          <>
            <IconButton icoUrl={exitIcon} icoAlt="exit" />
            로그아웃
          </>
        )}
      </LogoutWrapper>
    </HeaderWrapper>
  );
};
