import { Chip, styled } from "@mui/material";
import { FC } from "react";
import { ChipButtonProps } from "./ChipButton.type";

const StyledChipButton = styled(Chip)<ChipButtonProps>(({ theme }) => ({
  "&.MuiChip-root": {
    "&.check": {
      backgroundColor: theme.palette.selectGreen.main,
    },
    "&.cancel": {
      backgroundColor: theme.palette.cancelRed.main,
      color: theme.palette.white.main,
    },
  },
  [theme.breakpoints.up("tablet")]: {
    width: "120px",
    height: "40px",
  },
  [theme.breakpoints.up("laptop")]: {
    width: "120px",
    height: "40px",
  },

  fontSize: "13px",
  borderRadius: "10px",
  width: "120px",
  height: "30px",
}));

export const ChipButton: FC<ChipButtonProps> = ({ ...rest }) => (
  <StyledChipButton {...rest} />
);

export default ChipButton;
