import { styled, Box } from "@mui/material";
import { FC, useMemo } from "react";
import { ReservationStatusLabelProps } from "./ReservationStatusLabel.type";
import { EReservationStatus } from "../../../common/commonContants";

const LabelWrapper = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    "&.current": {
      color: "#289502",
      backgroundColor: "#A5FB9E",
    },
    "&.past": {
      color: "#FF0754",
      backgroundColor: "#FCC3C3",
    },
    "&.future": {
      color: "#2B27FD",
      backgroundColor: "#B0E3FF",
    },
  },
  [theme.breakpoints.up("tablet")]: {
    width: "123px",
    height: "36px",
    fontSize: "18px",
  },
  display: "flex",
  width: "60px",
  height: "22px",
  fontSize: "12px",
  borderRadius: "100px",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "700",
}));

export const ReservationStatusLabel: FC<ReservationStatusLabelProps> = ({
  status,
}) => {
  const [curState, curStateName] = useMemo(() => {
    switch (status) {
      case EReservationStatus.INPROGRESS:
        return ["current", "진행중"];
      case EReservationStatus.SCHEDULED:
        return ["future", "예정"];
      case EReservationStatus.COMPLETED:
        return ["past", "완료"];
      default:
        return ["", ""];
    }
  }, [status]);

  return <LabelWrapper className={curState}>{curStateName}</LabelWrapper>;
};
