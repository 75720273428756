import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Theme, Typography, styled, useMediaQuery } from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ReservationCarousel,
  ReservationList,
  VacancyAlarmList,
} from "../../organisms";
import { ProfileCard } from "../../molecules";
import { MyPageTemplateProps } from "./MyPageTemplate.type";
import { a11yProps, Tab, Tabs, TabPanel, IconButton } from "../../atoms";
import rightArrowIcon from "../../../assets/img/ico_right_arrow.svg";
import { useMenu } from "../../../pages/MainPage/hooks/useMenu";

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    fontSize: "16px",
  },
  fontSize: "24px",
}));

const CardArea = styled(Box)(() => ({}));

const ProfileCardWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    padding: "1rem 0",
  },
}));

const ReservationWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("laptop")]: {
    paddingBottom: "32px",
  },
}));

const VacancyAlarmWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("laptop")]: {
    height: "200px",
  },
}));

const VacancyAlarmTitleWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("laptop")]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const TabArea = styled(Box)({
  flex: "1",
});

const StyledTab = styled(Tab)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    minHeight: "20px",
    padding: "10px",
    width: "fit-content",
  },
  [theme.breakpoints.up("tablet")]: {
    width: "fit-content",
    fontSize: "20px",
    padding: "20px",
  },
}));

const StyledTabPanel = styled(TabPanel)({
  height: "calc(100% - 10px)",
  flex: "none",
  display: "flex",
  flexDirection: "column",
});

const StyledTabs = styled(Tabs)({
  ".MuiTabs-flexContainer": {
    borderBottom: "0px",
  },
});

const TAB_DATA = [
  { value: 0, name: "예약 내역" },
  { value: 1, name: "빈자리 알림 내역" },
];

// Notes
// 각 컴포넌트의 크기는 테블릿을 기준으로 커지고
// 레이아웃은 랩탑을 기준으로 바뀌게 구성
export const MyPageTemplate: FC<MyPageTemplateProps> = ({
  scheduledReservation,
  monthlyReservation,
}) => {
  const navigate = useNavigate();
  const thingList = useMenu();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("tablet")
  );
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("laptop")
  );
  const isLaptop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("laptop")
  );

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClickShortCutVacancyAlarm = () => {
    navigate("/vacancy-alarm");
  };
  return (
    // row spacing이 제대로 동작하기 위해선 nested grid의 경우
    // grid가 item과 container를 동시에 할 수 없다.
    <Box display="flex" justifyContent="center">
      <Grid2
        container
        rowSpacing={4}
        margin="20px 20px"
        paddingTop={isMobile || isTablet ? "50px" : "120px"}
        width={isLaptop ? 1000 : "auto"}
      >
        <Grid2 mobile={12} laptop={6}>
          <Grid2
            container
            direction="column"
            alignItems={isLaptop ? "flex-end" : "center"}
          >
            <CardArea width="100%">
              {isLaptop && <StyledTypography>내 프로필</StyledTypography>}
              <ProfileCardWrapper>
                <ProfileCard />
              </ProfileCardWrapper>
            </CardArea>
          </Grid2>
        </Grid2>
        <Grid2 mobile={12} laptop={6}>
          <Grid2
            container
            direction="column"
            alignItems={isLaptop ? "flex-start" : "center"}
          >
            <CardArea
              width="100%"
              maxWidth={isMobile ? "400px" : isTablet ? "600px" : "370px"}
              marginLeft={isLaptop ? "30px" : "0px"}
            >
              <StyledTypography>예정된 예약</StyledTypography>
              <Grid2 minHeight={isMobile ? "110px" : "158px"}>
                <ReservationCarousel
                  scheduledReservation={scheduledReservation}
                />
              </Grid2>
            </CardArea>
          </Grid2>
        </Grid2>
        <Grid2 mobile={12} laptop={12}>
          {!isTablet && (
            <TabArea>
              <StyledTabs
                value={tabValue}
                onChange={handleTabChange}
                ara-aria-label="activities tabs"
              >
                {TAB_DATA.map((tab) => (
                  <StyledTab label={tab.name} {...a11yProps(tab.value)} />
                ))}
              </StyledTabs>
              <StyledTabPanel value={tabValue} index={0}>
                <ReservationList monthlyReservation={monthlyReservation} />
              </StyledTabPanel>
              <StyledTabPanel value={tabValue} index={1}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickShortCutVacancyAlarm}
                >
                  <Typography fontSize="20px" paddingRight="5px">
                    바로가기
                  </Typography>
                  <IconButton
                    icoUrl={rightArrowIcon}
                    width="20px"
                    icoAlt="arrow"
                  />
                </Box>
                <VacancyAlarmList />
              </StyledTabPanel>
            </TabArea>
          )}
          {isTablet && (
            <>
              <ReservationWrapper>
                <StyledTypography>
                  예약 내역
                  {monthlyReservation && monthlyReservation.length > 0
                    ? ` (${monthlyReservation.length})`
                    : ""}
                </StyledTypography>
                <Box minHeight="80px">
                  <ReservationList monthlyReservation={monthlyReservation} />
                </Box>
              </ReservationWrapper>
              <VacancyAlarmWrapper>
                <VacancyAlarmTitleWrapper>
                  <StyledTypography>빈자리 알림 내역</StyledTypography>
                  <Box
                    onClick={handleClickShortCutVacancyAlarm}
                    sx={{ cursor: "pointer", display: "flex" }}
                  >
                    <Typography fontSize="14px" paddingRight="5px">
                      바로가기
                    </Typography>
                    <IconButton
                      icoUrl={rightArrowIcon}
                      width="14px"
                      icoAlt="arrow"
                    />
                  </Box>
                </VacancyAlarmTitleWrapper>
                {/** 안마의자에 대한 값으로 고정, 추후 thing 추가시 구조 변경 필요 */}
                {thingList && (
                  <VacancyAlarmList imageUrl={thingList[0].imageUrl} />
                )}
              </VacancyAlarmWrapper>
            </>
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
};

// Grid2 에서는 Grid가 item일 때 item을 props로 명시해줄 필요가 없음
