import { useSearchParams } from "react-router-dom";
import { useSlackLogin } from "./hooks/useSlackLogin";

// slack 로그인 과정 중 redirect uri에 해당하는 페이지
// auth code를 백엔드 서버에 넘겨주고 응답으로 받는 토큰들을 토대로 로그인 처리를 담당하는 페이지
export const SlackLoginHandlerPage = () => {
  const [searchParams] = useSearchParams();

  useSlackLogin(searchParams.get("code"));

  return <div />;
};
