import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  Color,
} from "@mui/material";

/**
 * 커스텀 색상 인터페이스 - 특정 이름의 색상 추가 시 사용
 */
type Partialize<T> = {
  [P in keyof T]?: T[P] extends Function
    ? T[P]
    : T[P] extends PaletteColor
    ? PaletteColorOptions
    : Partial<T[P]>;
};

interface CustomPalette {
  selectGreen: PaletteColor;
  primaryPink: PaletteColor;
  secondaryBlue: PaletteColor;
  cancelRed: PaletteColor;
  white: PaletteColor;
  useStatus: {
    inUseFillColor: string;
    inUseStrokeColor: string;
    notInUseFillColor: string;
    notInUseStrokeColor: string;
  };
}

type CustomSimple = Record<"grey", Color>;

declare module "@mui/material/styles" {
  interface Palette extends CustomPalette, CustomSimple {}
  interface PaletteOptions
    extends Partialize<CustomPalette>,
      Partialize<CustomSimple> {}
}

/**
 * custom breakpoints
 */
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

/**
 * 색상과 폰트 등 기본적인 테마 정의
 */
const theme = createTheme({
  typography: {
    fontFamily: "NanumSquareNeo",
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  palette: {
    primary: {
      main: "#888888",
    },
    white: {
      main: "#ffffff",
    },
    primaryPink: {
      main: "#FD2769",
    },
    secondaryBlue: {
      main: "#277DFD",
    },
    selectGreen: {
      main: "#27FD96",
    },
    cancelRed: {
      main: "#FD2679",
    },
    grey: {
      400: "#4E4E4E",
      300: "#888888",
      200: "#DDDDDD",
      100: "#EBEBEB",
    },
    useStatus: {
      inUseFillColor: "#950764",
      inUseStrokeColor: "#FD275A",
      notInUseFillColor: "#077395",
      notInUseStrokeColor: "#273CFD",
    },
  },
});

export default theme;
