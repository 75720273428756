import { Outlet } from "react-router-dom";
import { Theme, useMediaQuery } from "@mui/material";
import { HeaderGNB } from "../../organisms";

export const ConditionalHeaderRequiredTemplate = ({
  isLoginPage,
}: {
  isLoginPage: boolean;
}) => {
  const isLaptop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("laptop")
  );
  return (
    <>
      {isLaptop && <HeaderGNB isLoginPage={isLoginPage} />}
      <Outlet />
    </>
  );
};
