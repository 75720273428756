import { FC /* , useEffect */ } from "react";
// import { useNavigate } from "react-router-dom";
import { SlackLoginPageProps } from "./SlackLoginPage.type";
import { SlackLoginPageTemplate } from "../../components";

export const SlackLoginPage: FC<SlackLoginPageProps> = () => (
  // const navigate = useNavigate();
  // useEffect(() => navigate("/"), []);
  <SlackLoginPageTemplate />
);
