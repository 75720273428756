import { useQuery } from "@tanstack/react-query";
import { VacancyAlarmType } from "../../../model/VacancyAlarm.type";
import Api from "../../../services/ApiService";
import { useIsAuthenticated } from "../../AuthRequiredApp/hooks/useIsAuthenticated";
import { queryKeys } from "../../../react-query";

const fetchVacancyAlarmList = async (): Promise<VacancyAlarmType[]> => {
  const { data } = await Api.get<VacancyAlarmType[]>(`/vacancy-alarm/alarms`);
  return data;
};

export const useVacancyAlarmList = () => {
  const isAuthenticated = useIsAuthenticated();
  const { data: response, refetch } = useQuery<VacancyAlarmType[]>(
    [queryKeys.vacancyAlarm],
    () => fetchVacancyAlarmList(),
    {
      enabled: isAuthenticated,
    }
  );
  return { response, refetch };
};
