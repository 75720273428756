import { FC } from "react";
import { Grid, Typography, styled } from "@mui/material";
import { keyframes } from "@mui/system";
import { MainPageTemplateProps } from "./MainPageTemplate.type";

import { ImageCardList } from "../../organisms";

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const MainPageWrapper = styled(Grid)({
  justifyContent: "center",
  alignItems: "center",
  minHeight: "calc(100vh + 70px)",
  width: "100%",
});

const MainPageIntro = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    fontSize: "30px",
    marginTop: "-50px",
    marginBottom: "50px",
  },
  fontSize: "20px",
  marginBottom: "50px",
  marginTop: "-200px",
}));

interface CurrentUseStatusProps {
  canUse: boolean;
}

const CurrentUseStatus = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "canUse",
})<CurrentUseStatusProps>(({ theme, canUse }) => ({
  [theme.breakpoints.up("laptop")]: {
    marginTop: "50px",
    fontSize: "24px",
  },
  marginTop: "50px",
  fontSize: "16px",
  animation: `${blink} 1.5s linear infinite`,
  WebkitTextFillColor: canUse
    ? theme.palette.useStatus.notInUseFillColor
    : theme.palette.useStatus.inUseFillColor,
  WebkitTextStrokeColor: canUse
    ? theme.palette.useStatus.notInUseStrokeColor
    : theme.palette.useStatus.inUseStrokeColor,
  WebkitTextStrokeWidth: "1px",
}));

export const MainPageTemplate: FC<MainPageTemplateProps> = ({
  menuList,
  currentUseStatusText,
  canUse,
}) => (
  <MainPageWrapper container direction="column">
    <MainPageIntro>예약을 원하는 서비스를 선택하세요.</MainPageIntro>
    <ImageCardList menuList={menuList} />
    <CurrentUseStatus canUse={canUse}>{currentUseStatusText}</CurrentUseStatus>
  </MainPageWrapper>
);
